/**
 * 模块名称: 资产采购管理
 * @author liujingxue@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Purchase from './Purchase'
import './assets/style.scss'

const AssetIndex = ({ match, history }) => {

  return (
    <Switch>
      <Route path={match.path + '/purchase'} component={Purchase} />
      <Route component={Purchase} />
    </Switch>
  )
}

export default AssetIndex