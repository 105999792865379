/**
 * 模块名称: 工作日设置
 * @author xuzhongyuan@372163.com
 */

import React, { useState, useEffect } from 'react'
import CompanyBackup from 'components/CompanyBackup'
import Auth from 'components/AuthMiddleware'
import api from 'api'
import { Calendar, Button, Select, message, Modal, Icon } from 'antd'
import './assets/style.scss'
import moment from 'moment'

const { Option } = Select
const year = moment().year()
const month = moment().month()
// console.log(year + '年' + month + '月')
const options = []
for (let i = year; i < year + 3; i += 1) {
  options.push(<Option key={i} value={i} className="year-item">{i}</Option>)
}

const filter = {
  corpId: '',
  year,
  month
}

const Workday = (props) => {
  const { history } = props
  const [monthWorkday, setMonthWorkday] = useState([])
  const [workdays, setWorkdays] = useState([])
  const [currentMonth, setCurrentMonth] = useState(moment())
  const [currentDate, setCurrentDate] = useState(moment())
  const [loading, setLoading] = useState(false)//eslint-disable-line
  const [authList, setAuthList] = useState([])
  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    filter.year = moment().year()
    filter.month = moment().month()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  const getWorkdays = () => {
    api.getWorkDays({
      corpId: filter.corpId,
      year: filter.year,
      month: filter.month + 1
    }).then(data => {
      setWorkdays(data)
      setLoading(false)
    })
  }

  // 获取一年中12个月的工作日
  const getMonthWorkDays = () => {
    api.getMonthWorkDays({
      corpId: filter.corpId,
      year: filter.year
    }, true).then(data => {
      setMonthWorkday(data)
    })
  }

  const onChangeCorp = data => {
    if(data && data.id){
      filter.corpId = data.id
      getMonthWorkDays()
      getWorkdays()
    }else{
      message.error('没有所属公司权限！')
      const timer = setTimeout(() => {
        history.push('/index')
        clearTimeout(timer)
      }, 1000)
    }
  }

  const headerRender = ({ value, type, onChange }) => {
    return (
      <div className="calendar-header">
        <Select
          size="small"
          style={{ width: 120 }}
          dropdownMatchSelectWidth={false}
          defaultValue={year}
          onChange={newYear => {
            // console.log('newYear ==>', newYear)
            filter.year = newYear
            filter.month = 0
            getMonthWorkDays()
            getWorkdays()
            setCurrentMonth(moment(newYear + '-01-01'))
            setCurrentDate(moment(newYear + '-01-01'))
          }}
        >
          {options}
        </Select>
      </div>
    )
  }

  const monthCellRender = value => {
    if (monthWorkday.length) {
      return <span className="workday-count">共{monthWorkday[value.month()].count}工作日</span>
    }
    return null
  }

  // 月份切换
  const onSelectMonthCalendar = date => {
    //console.log(date.year(), date.month())
    filter.month = date.month()
    setLoading(true)
    setCurrentMonth(date)
    setCurrentDate(date)
    getWorkdays()
  }

  // 工作日状态切换
  const onClickDateCell = (date, status) => {
    // console.log(date, status)
    if(!authList.includes('operate'))return message.warning('您没有设置工作日的权限')
    api.setWorkday({
      corpId: filter.corpId,
      date: date,
      status: +status
    }, true).then(() => {
      getWorkdays()
      // console.log(monthWorkday)
      setMonthWorkday(monthWorkday.map(item => (date.includes(item.month) ? { ...item, count: status ? item.count - 1 : item.count + 1 } : item)))
    })
  }

  const dateCellRender = (value) => {
    // console.log('value ==>', value.format('YYYY-MM-DD'))
    // console.log('value month ==>', value.month())
    // console.log('filter month ==>', filter.month)

    if (workdays.length === 0) {
      return null
    }

    if (value.month() === filter.month) {
      const date = value.format('YYYY-MM-DD')
      const day = workdays.find(item => item.date === date)
      if (day) {
        return (
          <span
            onClick={() => onClickDateCell(date, day.status)}
            className={`date-cell ${day.status ? 'work-day' : 'vacation-day'}`}
          >
            {value.date()}
          </span>
        )
      }
    } else {
      return <span className="date-cell disabled">{value.date()}</span>
    }
    return null
  }

  const onSetAll = () => {
    api.setAllWorkDay({ corpId: filter.corpId, year: filter.year }, true).then(() => {
      message.success('设置成功')
      setModalVisible(false)
    }).catch(() => setModalVisible(false))
  }

  return (
    <>
      <CompanyBackup onChange={onChangeCorp} radio notHeadOffice />
      <div className="calendar-tags">
        <span className="workday-tag">工作日</span>
        <span className="playday-tag">休息日</span>
      </div>
      <div className="calender-wrap">
        <div className="calendar-month">
          <Calendar
            mode="year"
            headerRender={headerRender}
            monthCellRender={monthCellRender}
            onSelect={onSelectMonthCalendar}
            value={currentMonth}
          />
          <div className="calendar-btn">
            <Auth auths={authList} code="workday-push-all">
              <Button type="primary" onClick={() => setModalVisible(true)}>将今年设置推广至全公司</Button>
            </Auth>
          </div>
        </div>

        <div className="calendar-days">
          <Calendar
            value={currentDate}
            headerRender={() => null}
            dateFullCellRender={dateCellRender}
          />
        </div>
      </div>
      <Modal
        title={<><Icon type="info-circle" style={{color: '#faad14', fontSize: '17px'}} /> 请注意！</>}
        visible={modalVisible}
        onOk={onSetAll}
        onCancel={() => setModalVisible(false)}
        okText="继续提交"
        cancelText="返回修改"
      >
        此次操作将对所有公司已设置工作日进行覆盖操作，请谨慎操作！
      </Modal>
    </>
  )
}

export default Workday