/**
 * 模块名称: 资产采购管理 => 详情页
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import {
	Descriptions,
	Spin,
	Icon,
	message,
	Steps,
	Button,
	Modal,
	Input,
	Form,
	Table,
	Upload
} from 'antd'
import api from '@/api'
import { useSelector } from 'react-redux'
import { upLoadModule } from '@/utils/common'
import { parseSearch, debounce } from '@/utils'
import CryptoJS from 'crypto-js'
import '../assets/style.scss'

const { Column } = Table
const { Step } = Steps
const dItem = Descriptions.item
const {Dragger} = Upload

let areaTxt = '', result = ''

let currentPage = 1
let limit = 10, page = 1

const Details = (props) => {
	const { history, location } = props
	const { getFieldDecorator } = props.form
	const userInfo = useSelector(state => state.userInfo)
	const draggerRef = useRef(null)
	const [detail, setDetail] = useState({})
	const [flow, setFlow] = useState([])
	const [modalVisible, setModalVisible] = useState(false)
	const [pageId, setPageId] = useState('')
	const [auditRecord, setAuditRecord] = useState([])
	const [loading, setLoading] = useState(false)
	const [resPlaceholder, setResPlaceholder] = useState('请输入至少1个字符')
	const [files, setFiles] = useState([])
	const [paymentList, setPaymentList] = useState([])
	const [currentBusinessStatus, setCurrentBusinessStatus] = useState({})
	const [certificate, setCertificate] = useState([])
	//修改
	const [reviseVisible, setReviseVisible] = useState(false)
	const [fileList, setFileList] = useState([])

	//操作权限
	const [auditAuth, setAuditAuth] = useState(false)//设置按钮组是否展示
	const [hasChargerPower, setHasChargerPower] = useState(false)//设置驳回和通过按钮是否展示
	const [replenish, setReplenish] = useState(false)//设置补充资料按钮是否展示
	const [returnUp, setReturnUp] = useState(false)//设置转上级按钮是否展示
	const [rejectMsgRequire, setRejectMsgRequire] = useState(false)//设置审批意见驳回必填

	useEffect(() => {
		let plaintext = parseSearch(location.search).sign
		let plaintextTwo = CryptoJS.MD5(parseSearch(location.search).id + 'ad629fddf8b8756d2e72e96ae035a5a4' + parseSearch(location.search).time).toString()

		if (plaintext == plaintextTwo && +new Date() - +parseSearch(location.search).time <= 21600000) {
			areaTxt = ''
			result = ''
			if (location.search) {
				setPageId(parseSearch(location.search).id)
				getAssetRowDetail(parseSearch(location.search).id)
			} else {
				history.push({
					pathname: '/asset/purchase'
				})
			}
		} else {
			message.error('没有本页访问权限')
			setTimeout(() => {
				history.go(-2)
			}, 1000)
		}
	}, [])

	//获取详情
	const getAssetRowDetail = (id) => {
		api.getAssetRowDetail({ id: id, type: 0 }).then(res => {
			if (parseSearch(location.search).msgId) {
				api.setMessageReaded({ ids: [parseSearch(location.search).msgId] })
			}
			if (JSON.stringify(res) !== "[]") {
				let data = Object.assign({}, res)
				if (data.status === 0) data.current = 0
				if (data.status === 1) data.current = 1
				if (data.status === 2) data.current = 2
				if (data.status === 3) data.current = 3
				// console.log(data.affix)
				data.affix && setFiles(JSON.parse(data.affix))
				if (data.purchaseDetail.length > 0) {
					let newArr = data.purchaseDetail
					newArr.map((v, i) => v.ids = i + 1)
					setPaymentList(newArr)
				}
				setCurrentBusinessStatus(data.currentBusinessStatus)
				// console.log(JSON.parse(data.currentBusinessStatus.certificate))
				data.currentBusinessStatus.certificate && setCertificate(JSON.parse(data.currentBusinessStatus.certificate))
				setDetail(data)
				let auditFlow = data.audit_flow
				auditFlow.forEach((item, index, self) => {
					if (item.persons && item.persons.length) {
						self[index].persons = item.persons.join('，')
					}
				})
				setFlow(auditFlow)
				setAuditRecord(data.audit_log)
				if (data.auditAuth && JSON.stringify(data.auditAuth) !== '[]') {
					setAuditAuth(true)
					setHasChargerPower(data.auditAuth.hasChargerPower)
					setReplenish(data.auditAuth.replenish)
					setReturnUp(data.auditAuth.return_up)
					setRejectMsgRequire(data.auditAuth.reject_msg_require)
				} else {
					setAuditAuth(false)
					setHasChargerPower(false)
					setReplenish(false)
					setReturnUp(false)
					setRejectMsgRequire(false)
				}
				// setOldData(data.oldData)
			} else {
				setDetail({})
				setFlow([])
				setAuditRecord([])
			}
			setLoading(false)
		})
	}

	//点击4个审批的按钮
	const leaveOutAudit = (no) => {
		areaTxt = ''
		result = no
		if (no == 0) {
			if (detail.luruhetongFlag) {
				setResPlaceholder('请输入审批意见')
				setModalVisible(true)
			} else {
				setResPlaceholder('请输入审批意见')
				setModalVisible(true)
			}

		} else if (no == 1) {
			//审批意见是否为必填项改由审批流控制 2020年2月22日 17:19:14
			setResPlaceholder('请输入审批意见')
			setModalVisible(true)
		}
		// else {
		// 	api.setassetPurchaseAudit({
		// 		id: pageId,
		// 		type: result,
		// 		remark: areaTxt
		// 	}).then(res => {
		// 		//todo 转上级或者补充资料 返回列表页
		// 		let timer = setTimeout(() => {
		// 			history.push({
		// 				pathname: '/asset/purchase'
		// 			})
		// 			clearTimeout(timer)
		// 		}, 1000)
		// 	})
		// }
	}

	const oprateHandleOk = () => {
		if (areaTxt.length < 1 && result == 1 && rejectMsgRequire) {
			message.warning('请输入至少1个字符')
			return
		}
		let params = {
			id: pageId,
			type: result,
			remark: areaTxt
		}
		api.setassetPurchaseAudit(params).then(res => {
			message.success('审批成功')
			setModalVisible(false)
			getAssetRowDetail(pageId)
		})
	}

	//取消弹窗
	const oprateHandleCancel = () => {
		setModalVisible(false)
	}

	//同意的样式
	const agreeNode = () => {
		return result === 0 ? <>
			通过 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
		</> : result === 1 ? <>驳回 <Icon type="close-circle" theme="twoTone" twoToneColor="#eb2f96" /></> :
			<>转上级</>
	}

	//审批意见
	const getAreaTxt = (e) => {
		areaTxt = e.target.value.trim()
	}

	//退回列表页
	const onGoBack = () => {
		history.push({ pathname: '/asset/purchase', state: { goBack: 1 } })
	}

	//修改
	const onClickRevise = () => {
		setReviseVisible(true)
	}

	//确认修改按钮
	const handleOk = () => {
		let uploadList = []
		for (let i = 0, k = fileList.length; i < k; i++) {
			if (fileList[i].status != 'removed') {
			uploadList.push({
				url: fileList[i].url,
				name: fileList[i].name
			})
			}
		}
		api.setAssetPurchaseModifyPaymentVoucher({
			id: pageId,
			certificate: uploadList
		}).then(res => {
			message.success('修改成功')
			setReviseVisible(false)
			setFileList([])
			getAssetRowDetail(pageId)
		}).catch(err => console.log(err))
	}

	// 文件上传
    const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: ".zip, .pdf, .jpg, .png, .xls, .xlsx",
		limitNum: 20
	})

	// //付费明细列表分页
	// const onChangeTable = (pagination, filters, sorter) => {
	// 	page = currentPage = pagination.current
	// 	limit = pagination.pageSize
	// 	// if (search.id) {
	// 	// 		getUncollectedBillsList(initialData.fromRelationType, initialData.fromRelationId)
	// 	// } else {
	// 	// 		getUncollectedBillsList()
	// 	// }
	// }

	return (
		<>
			<Spin spinning={loading}>
				{
					parseSearch(location.search).new && parseSearch(location.search).new == 1 && <Button onClick={onGoBack} type="primary" style={{
						position: 'absolute',
						right: 0,
						top: '-20px'
					}}>返回上一页</Button>
				}
				<div style={{ padding: '5px 0' }}>
					<div>
						<div style={{ fontSize: '14px', marginBottom: '5px', color: '#000' }}>
							业务编号：<span style={{ fontSize: '16px' }}>{detail.id}</span>
						</div>
					</div>
				</div>
				<div className="line"></div>
				<div className="cost-management-type">
					<div className="cost-management-type-title">当前状态 <Button type="primary" style={{ marginLeft: '20px' }}>{currentBusinessStatus.businessStatusName}</Button></div>
					<div>{currentBusinessStatus.businessStatus == 5 ? `终止原因：${currentBusinessStatus.failedReason}` : ''}</div>
					{/* <div>{currentBusinessStatus.busCodeName == '已驳回' ? `审批意见：${currentBusinessStatus.approvalComments}` : ''}</div> */}
					<div>
						{currentBusinessStatus.businessStatus == 4 ? <>
							<span>付款凭证：</span>
							{certificate.length > 0 && certificate.map((v, i) => <a style={{ marginLeft: '15px' }} key={i} href={v.url} target="_blank" rel="noopener noreferrer">{v.name} </a>)}
							{detail.editCertificate && <a style={{ marginLeft: '30px' }} onClick={onClickRevise}>修改</a>}
							<Modal
								title="修改"
								visible={reviseVisible}
								onOk={handleOk}
								onCancel={() => {
									setReviseVisible(false)
									setFileList([])
								}}
								destroyOnClose={true}
							>
								<div className="dragger-box" ref={draggerRef}>
									<Dragger
										{...uploadFiles}
									>
										<p className="ant-upload-drag-icon">
											<Icon type="inbox" />
										</p>
										<p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
										<p className="ant-upload-hint">
											支持扩展名：xls、xlsx、png、jpg、pdf、zip 单个文件大小不超过5M，不超过20个附件。
										</p>
									</Dragger>
								</div>
							</Modal>
						</> : ''}
					</div>
					<div>{currentBusinessStatus.businessStatus == 5 ||
						currentBusinessStatus.businessStatus == 4 ? `操作人：${currentBusinessStatus.departName} ${currentBusinessStatus.operateName}` : ''}
					</div>
				</div>
				<div className="line"></div>
				<div className="step-wrap">
					<div className="contract-list-title">审批进度</div>
					<Steps current={detail.current}>
						{
							flow.map((item, index, self) => {
								let status = 'wait'
								if (item.time && item.time.length > 1) {
									status = 'process'
								}
								return <Step status={status} title={item.nodeName} key={index} description={
									<>
										{item.persons && <div title={item.persons}>{item.persons}</div>}
										<div title={item.statusName}>{item.statusName}</div>
										<div title={item.time}>{item.time}</div>
									</>
								} />
							})
						}
					</Steps>
				</div>
				<div className="line"></div>
				<Descriptions title="基本信息" layout="horizontal" column={3} className="order-detail-title" style={{ borderBottom: 'none', marginTop: '10px' }}>
					<dItem label="资产类型">{detail.typeName}</dItem>
					<dItem label="公司主体">{detail.corpName}</dItem>
					<dItem label="付款方式">{detail.payTypeName}</dItem>
				</Descriptions>
				<Descriptions layout="horizontal" column={3} style={{ borderBottom: 'none', marginTop: '10px' }}>
					<dItem label="收款单位">{detail.payee}</dItem>
					<dItem label="开户行"> {detail.payeeBank ? detail.payeeBank : '无'}</dItem>
					<dItem label="银行账号">{detail.payeeAccount ? detail.payeeAccount : '无'}</dItem>
				</Descriptions>
				<div className="line"></div>
				<Descriptions title="付款明细" layout="horizontal" column={1} className="order-detail-title" style={{ marginTop: '10px', borderBottom: 'none' }}>
					<dItem>
						<Table
							dataSource={paymentList}
							rowKey="id"
							pagination={false}
						>
							<Column title="序号" dataIndex="ids" />
							<Column title="资产名称" dataIndex="assetName" />
							<Column title="单位" dataIndex="unit" />
							<Column title="数量" dataIndex="num" />
							<Column title="单价" dataIndex="price" />
							<Column title="金额" dataIndex="amount" />
							<Column title="用途" dataIndex="purpose" />
							<Column title="采购平台" dataIndex="purchasePlatform" />
							<Column title="备注" dataIndex="remark" />
						</Table>
					</dItem>
					<dItem>
						<span style={{ fontWeight: 800 }}>付款总额：</span>
						<span style={{ fontWeight: 800, color: 'red', marginRight: '30px'}}>{detail.payMoney}</span>
						<span style={{ fontWeight: 800, color: 'red' }}>{detail.payMoneyFormat}</span>
					</dItem>
				</Descriptions>
				<div className="line"></div>
				<Descriptions layout="horizontal" column={1} style={{ borderBottom: 'none', marginTop: '10px' }}>
					<dItem label="附件">
						<>
							{
								files.length > 0 ? files.map((item, i) => {
									return <a key={i} href={item.url} target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' }}>{item.name}</a>
								}) : <div>无</div>
							}
						</>
					</dItem>
					<dItem label="备注">{detail.remark ? detail.remark : '无'}</dItem>
				</Descriptions>
				<div className="line"></div>
				<div className="record-list" style={{ marginTop: '10px', marginBottom: '50px' }}>
					<Descriptions title="审批记录" layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none", marginBottom: "-30px" }}>
						<dItem label="姓名"></dItem>
						<dItem label="时间"></dItem>
						<dItem label="状态"></dItem>
						<dItem label="审批意见"></dItem>
					</Descriptions>
					{
						auditRecord.map((item, index) => {
							return <Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index} style={{ borderBottom: "none", marginBottom: "-10px" }}>
								<dItem label="">{item.userInfo.staffName}</dItem>
								<dItem label="">{item.addTime}</dItem>
								<dItem label="">{item.status}</dItem>
								<dItem label="">{item.chargeMsg}</dItem>
							</Descriptions>
						})
					}
				</div>
				{/* <div className="line"></div> */}
			</Spin>
			<div className="contract-details-btn">
				<div className="contract-details-btns">
					{auditAuth && <div className="btns">
						{/* {replenish && <Button onClick={debounce(() => { leaveOutAudit(3) }, 3000)} className="orange-btn">补充资料</Button>}
			  {returnUp && <Button onClick={debounce(() => { leaveOutAudit(2) }, 3000)} className="orange-btn">转上级</Button>} */}
						{hasChargerPower && <Button onClick={() => leaveOutAudit(1)} style={{ marginLeft: '15px' }}>驳回</Button>}
						{hasChargerPower && <Button onClick={() => leaveOutAudit(0)} type="primary" style={{ marginLeft: '15px' }}>通过</Button>}
					</div>}
					<Modal
						title={agreeNode()}
						visible={modalVisible}
						onOk={debounce(() => { oprateHandleOk() }, 3000)}
						onCancel={oprateHandleCancel}
						destroyOnClose={true}
						footer={[
							<Button key="back" onClick={oprateHandleCancel}>取消</Button>,
							<Button key="submit" type="primary" onClick={debounce(() => { oprateHandleOk() }, 3000)}>
								确定
							</Button>]}
					>
						<div className="officework-modal-content">
							<span className="label">审批意见：</span><Input.TextArea className="content-area" placeholder={resPlaceholder} onChange={getAreaTxt} rows={4} style={{ width: "100%" }} maxLength={100} />
						</div>
					</Modal>
				</div>
			</div>
		</>
	)
}

export default Form.create()(Details)