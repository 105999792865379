/**
 * 模块名称: 资产采购管理 => 申请页
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import {
	Select,
	Input,
	Drawer,
	Form,
	message,
	Row,
	Col,
	Upload,
	Icon,
	TreeSelect,
	Table,
	Spin,
	Popconfirm,
	Button,
	Modal,
	Alert
} from 'antd'
import api from '@/api'
import BtnGroup from '@/components/BtnGroup'
import Import from '@/components/Import'
import { parseSearch } from '@/utils'
import { upLoadModule } from '@/utils/common'
import AddDetail from './AddDetail'
import '../assets/style.scss'

const { Column } = Table
const { Option } = Select
const { TreeNode } = TreeSelect
const { Dragger } = Upload
const FormItem = Form.Item

var temp
let pageSize = 10
let pageSize2 = 10

const Apply = (props) => {
	const addRef = useRef()
	const ImportRef = useRef()
	const { location, history, match } = props
	const { getFieldDecorator, setFieldsValue, validateFields } = props.form

	const draggerRef = useRef(null)
	const [fileList, setFileList] = useState([])
	const [payType, setPayType] = useState('')
	const [companyList, setCompanyList] = useState([])
	const [loading, setLoading] = useState(false)
	const [formLoading, setformLoading] = useState(false)
	const [assetType, setAssetType] = useState([])//获取资产类别
	const [assetPayType, setAssetPayType] = useState([])//获取付款方式
	const [visible, setVisible] = useState(false)//付款明细抽屉控制展示隐藏
	const [visibleImport, setVisibleImport] = useState(false)//导入结果弹窗
	const [paymentList, setPaymentList] = useState([])
	const [importResultList, setImportResultList] = useState([])//导入失败的数据
	const [count, setCount] = useState(0)//导入总条数
	const [failCount, setFailCount] = useState(0)//导入总条数
	const [payMoney, setPayMoney] = useState('')//金额小写
	const [showImport, setShowImport] = useState(false)//导入


	const search = parseSearch(location.search)

	useEffect(() => {
		common()
		if (search.id) {
			api.getAssetRowDetail({ id: search.id, type: 1 }, true).then(res => {
				const fileLists = JSON.parse(res.affix)
				setPayType(res.payType)
				setPayMoney(res.payMoney)
				setFieldsValue({
					type: res.type,
					corpId: res.corpId,
					payType: res.payType,
					payee: res.payee,
					remark: res.remark
				})
				if (res.payType !== 2) {
					setFieldsValue({
						payeeBank: res.payeeBank,
						payeeAccount: res.payeeAccount
					})
				}
				if (res.purchaseDetail.length > 0) {
					let newArr = res.purchaseDetail
					newArr.map((v, i) => v.ids = i + 1)
					setPaymentList(newArr)
				}
				if (fileLists.length) {
					fileLists.forEach((item, index) => {
						fileList.push({
							status: 'done',
							uid: index,
							name: item.name,
							url: item.url
						})
					})
					setFileList(fileLists.map((item, index) => ({ ...item, uid: index })))
				}
			})
		}
	}, [])

	//获取公共接口
	const common = () => {
		api.getVariousSelectionList().then(res => {
			setAssetType(res.types)//获取公司资产类别
			setAssetPayType(res.payTypes)//资产采购付款方式
		}).catch(err => console.log(err))
		api.getHeadCompanyList({ limit: global.paramsLimit, type: 'feiyong' }).then(data => setCompanyList(data))//公司主体
	}

	//添加
	const handleSubmit = e => {
		e.preventDefault()
		validateFields((err, vals) => {
			let uploadList = []
			for (let i = 0, k = fileList.length; i < k; i++) {
				if (fileList[i].status !== 'removed') {
					uploadList.push({
						url: fileList[i].url,
						name: fileList[i].name
					})
				}
			}
			if (!err) {
				// console.log(paymentList)
				let str = ''
				if (paymentList.length > 0) {
					str = (paymentList.map(v => v.id)).join(',')
				} else {
					return message.error('请添加明细后再提交！')
				}
				// console.log(str)
				api.setAssetHandle({
					id: search.id ? search.id : '',
					corpId: vals.corpId,
					type: vals.type,
					payType: vals.payType,
					payee: vals.payee,
					payeeBank: vals.payeeBank,
					payeeAccount: vals.payeeAccount,
					payMoney: payMoney,
					detailIds: str,
					remark: vals.remark,
					affix: JSON.stringify(uploadList)
				}).then(() => {
					history.push('/asset/purchase')
				})
			} else {
				message.error('请填写必填内容')
			}
		})
	}

	//取消返回列表页
	const onCancel = () => history.push('/asset/purchase')

	// 文件上传 
	const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.gif, .png, .jpg, .jpeg, .PDF, .zip, .xls, .xlsx',
		allowSizeType: 2,
		limitNum: 20
	})

	//付款方式
	const onSelectPayType = (values) => {
		setPayType(values)
	}

	//添加明细
	const onClickAdd = () => {
		setVisible(true)
	}

	//添加明细 => 删除
	const onDelete = (val) => {
		// console.log('val=>', val)
		api.setAssetDetailDel({ id: val.id }).then(res => {
			let arr = paymentList.filter(v => {
				return v.ids !== val.ids
			})
			arr.map((v, i) => v.ids = i + 1)
			setPaymentList(arr)
			let num = eval(arr.map(v => v.amount).join("+"))
			if (num) {
				setPayMoney(num.toFixed(2))
			} else {
				setPayMoney(num)
			}
			
			
		})
	}

	//添加明细 => 编辑
	const onClickEdit = (data) => {
		// console.log('data=>', data)
		setVisible(true)
		setformLoading(true)
		setTimeout(() => {
			if (addRef.current) addRef.current.changeVal(data)
			setformLoading(false)
		}, 600)
	}

	//转大写
	const DX = (tranvalue) => {
		try {
			var i = 1;
			var dw2 = new Array("", "万", "亿");//大单位
			var dw1 = new Array("拾", "佰", "仟");//小单位
			var dw = new Array("零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖");//整数部分用
			//以下是小写转换成大写显示在合计大写的文本框中     
			//分离整数与小数
			var source = splits(tranvalue);
			var num = source[0];
			var dig = source[1];

			//转换整数部分
			var k1 = 0;//计小单位
			var k2 = 0;//计大单位
			var sum = 0;
			var str = "";
			var len = source[0].length;//整数的长度
			for (i = 1; i <= len; i++) {
				var n = source[0].charAt(len - i);//取得某个位数上的数字
				var bn = 0;
				if (len - i - 1 >= 0) {
					bn = source[0].charAt(len - i - 1);//取得某个位数前一位上的数字
				}
				sum = sum + Number(n);
				if (sum != 0) {
					str = dw[Number(n)].concat(str);//取得该数字对应的大写数字，并插入到str字符串的前面
					if (n == '0') sum = 0;
				}
				if (len - i - 1 >= 0) {//在数字范围内
					if (k1 != 3) {//加小单位
						if (bn != 0) {
							str = dw1[k1].concat(str);
						}
						k1++;
					} else {//不加小单位，加大单位
						k1 = 0;
						temp = str.charAt(0);
						if (temp == "万" || temp == "亿")//若大单位前没有数字则舍去大单位
							str = str.substr(1, str.length - 1);
						str = dw2[k2].concat(str);
						sum = 0;
					}
				}
				if (k1 == 3)//小单位到千则大单位进一
				{ k2++; }
			}

			//转换小数部分
			var strdig = "";
			if (dig != "") {
				var n = dig.charAt(0);
				if (n != 0) {
					strdig += dw[Number(n)] + "角";//加数字
				}
				var n = dig.charAt(1);
				if (n != 0) {
					strdig += dw[Number(n)] + "分";//加数字
				}
			}
			if (strdig !== '') {
				if (str !== '') {
					str += "元" + strdig;
				} else {
					str += strdig;
				}

			} else {
				str += "元" + strdig + '整'
			}

		} catch (e) {
			return "0元";
		}
		return str;
	}

	//拆分整数与小数
	function splits(tranvalue) {
		var value = new Array('', '');
		temp = tranvalue.split(".");
		for (var i = 0; i < temp.length; i++) {
			value[i] = temp[i];
		}
		return value;
	}

	//分页点击
  const onChangeTable = (current, size) => {
		let bak = paymentList.slice()
    setPaymentList([])
    pageSize = size
    setPaymentList(bak)
  }

	const onChangeTable2 = (current, size) => {
		let bak = importResultList.slice()
    setImportResultList([])
    pageSize = size
    setImportResultList(bak)
    
  }

	//导入
	const onShowImport = () => {
		ImportRef.current.onRemoved()
    setShowImport(true)
	}

	// 导入确定
  const onConfirmImport = (fileList) => {
    if (fileList.length > 0) {
      if (fileList[0].status !== 'removed') {
        api.setAssetDetailImport({
          uploadUrl: fileList[0].url
        }).then(res => {
					// console.log(res)
					setImportResultList(res.importResult.list)
					setCount(res.importResult.count)
					setFailCount(res.importResult.failCount)
					let newArr
					let num
					if (paymentList.length > 0) {
						newArr = paymentList.concat(res.importData.list)
					} else {
						newArr = res.importData.list
					}
					if (newArr.length > 0) {
						newArr.map((v, i) => v.ids = i + 1)
						num = eval(newArr.map(v => v.amount).join("+"))
						setPaymentList(newArr)
						setPayMoney(num.toFixed(2))
					}
          // getEmployeeList()
          // message.success('导入成功')
					setVisibleImport(true)
          ImportRef.current.onLoading()
          setShowImport(false)
        }).catch(res => ImportRef.current.onLoading())
      } else {
        message.error('请导入模板')
      }
    } else {
      message.error('请导入模板')
    }
  }

	return (
		<>
			<div className="cost-management-apply">
				<h1 className="cost-management-apply-title">{search.id ? '采购驳回重提申请' : '采购申请'}</h1>
				<div className="line"></div>
				<Form onSubmit={handleSubmit}>
					<h3 className="cost-management-apply-tit">基本信息</h3>
					<Row gutter={24}>
						<Col span={6}>
							<FormItem label="资产类别">
								{getFieldDecorator('type', {
									rules: [{ required: true, message: '请选择' }]
								})(<Select placeholder="请选择" dropdownMatchSelectWidth={false}>
									{
										assetType.map((v, i) => {
											return <Option value={v.id} key={i}>{v.name}</Option>
										})
									}
								</Select>)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="公司主体">
								{getFieldDecorator('corpId', {
									rules: [{ required: true, message: '请选择' }]
								})(<Select placeholder="请选择" dropdownMatchSelectWidth={false}>
									{
										companyList.map((v, i) => {
											return <Option value={v.id} key={i}>{v.name}</Option>
										})
									}
								</Select>)}
							</FormItem>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={6}>
							<FormItem label="付款方式">
								{getFieldDecorator('payType', {
									rules: [{ required: true, message: '请选择' }]
								})(<Select placeholder="请选择" dropdownMatchSelectWidth={false} onSelect={onSelectPayType}>
									{
										assetPayType.map((v, i) => {
											return <Option value={v.id} key={i}>{v.name}</Option>
										})
									}
								</Select>)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="收款单位">
								{getFieldDecorator('payee', {
									rules: [{ required: true, message: '请输入' }]
								})(<Input placeholder="请输入" />)}
							</FormItem>
						</Col>
					</Row>
					{
						payType !== 2 && <Row gutter={24}>
							<Col span={6}>
								<FormItem label="开户行">
									{getFieldDecorator('payeeBank', {
										rules: [{ required: true, message: '请输入' }]
									})(<Input placeholder="请输入" />)}
								</FormItem>
							</Col>
							<Col span={6}>
								<FormItem label="银行账号">
									{getFieldDecorator('payeeAccount', {
										rules: [
											{ required: true, message: '请输入' },
											{
												pattern: /^-?[0-9]*(\.[0-9]*)?$/, message: '请输入正确的格式'
											}]
									})(<Input placeholder="请输入" />)}
								</FormItem>
							</Col>
						</Row>
					}
					{/* <Row gutter={24}>
						<Col span={6}>
							<FormItem label="付款金额">
								{getFieldDecorator('payMoney', {
									rules: [{ required: true, message: '请输入' }, {
										pattern: /^-?[0-9]*(\.[0-9]*)?$/, message: '请输入正确的格式'
									}]
								})(<Input placeholder="请输入" />)}
							</FormItem>
						</Col>
					</Row> */}
					<div className="cost-management-apply-cont">
						<h3 className="cost-management-apply-tits"><span className="cost-management-apply-tits-abs">*</span>付款明细</h3>
						<Button type="link" style={{ marginLeft: '20px' }} onClick={onShowImport}>导入</Button>
					</div>
					<Import
						onSearch={onConfirmImport}
						visibleFile={showImport}
						onCloseImport={() => setShowImport(false)}
						ImportTemplate="/api/File/DownTemplate?url=/templates/assetDetail.xlsx&name=资产明细导入模板"
						ImportRef={ImportRef}
					/>
					<Row gutter={24}>
						<Col span={24}>
							<Table
								dataSource={paymentList}
								rowKey="id"
								pagination={{
									pageSize,
									pageSizeOptions:['10', '50', '100', '500', '1000', '2000'],
									showQuickJumper: true,
									showSizeChanger: true,
									onShowSizeChange: onChangeTable
								}}
							>
								<Column title="序号" dataIndex="ids" />
								{/* <Column title="资产编号" dataIndex="assetNo" /> */}
								<Column title="资产名称" dataIndex="assetName" />
								<Column title="单位" dataIndex="unit" />
								<Column title="数量" dataIndex="num" />
								<Column title="单价" dataIndex="price" />
								<Column title="金额" dataIndex="amount" />
								<Column title="用途" dataIndex="purpose" />
								<Column title="采购平台" dataIndex="purchasePlatform" />
								<Column title="备注" dataIndex="remark" />
								<Column title={<a onClick={onClickAdd}>添加明细</a>} key="oprate" render={(text, record) => {
									return <>
										<a style={{ marginRight: '15px' }} onClick={() => onClickEdit(record)}>编辑</a>
										<Popconfirm title="请确认是否删除，删除后不可恢复。" cancelText="取消" okText="确认" onConfirm={() => onDelete(record)}>
											<a>删除</a>
										</Popconfirm>
									</>
								}} />
							</Table>
						</Col>
					</Row>
					<h3 className="cost-management-apply-tits">付款总额：
						<span style={{ color: 'red' }}>{payMoney}</span>
						<span style={{ color: 'red', marginLeft: '20px' }}>{payMoney && DX('' + payMoney)}</span>
					</h3>
					<Row gutter={24}>
						<Col span={16}>
							<FormItem label="附件">
								<div className="dragger-box" ref={draggerRef}>
									<Dragger
										{...uploadFiles}
									>
										<p className="ant-upload-drag-icon">
											<Icon type="inbox" />
										</p>
										<p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
										<p className="ant-upload-hint">
											附件支持格式：xls, xlsx、GIF、PNG、JPG、PDF、zip  支持添加多个附件，单个附件大小不超过5M，文件数量不超过20个。
										</p>
									</Dragger>
								</div>
							</FormItem>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={16}>
							<FormItem label="备注">
								{getFieldDecorator('remark')(
									<Input.TextArea placeholder="请输入" maxLength={100} />
								)}
							</FormItem>
						</Col>
					</Row>
					<BtnGroup confirmName="提交" onCancel={onCancel} />
				</Form>
			</div>
			<Drawer
				title="添加付款明细"
				width={720}
				onClose={() => setVisible(false)}
				destroyOnClose={true}
				maskClosable={false}
				visible={visible}
				bodyStyle={{ paddingBottom: 80 }}
			>
				<Spin spinning={formLoading}>
					<AddDetail
						setVisible={setVisible}
						setPaymentList={setPaymentList}
						paymentList={paymentList}
						setPayMoney={setPayMoney}
						cRef={addRef}
					/>
				</Spin>
			</Drawer>
			<Modal
				title="导入结果"
				visible={visibleImport}
				maskClosable={false}
				onCancel={() => setVisibleImport(false)}
				footer={null}
			>
				<div style={{ fontSize: '14px', marginBottom: '10px' }}>
					导入总条数：<span style={{ fontWeight: 800, marginRight: '30px' }}>{count}</span>
					失败总条数：<span style={{ fontWeight: 800 }}>{failCount}</span>
				</div>
				<Table
					dataSource={importResultList}
					rowKey="row"
					pagination={{
						pageSize2,
						pageSizeOptions: ['10', '50', '100', '500', '1000', '2000'],
						showQuickJumper: true,
						showSizeChanger: true,
						onShowSizeChange: onChangeTable2
					}}
				>
					<Column title="行数" dataIndex="row" />
					<Column title="字段" dataIndex="field" />
					<Column title="失败原因" dataIndex="failReason" />
				</Table>
			</Modal>
		</>
	)
}

export default Form.create()(Apply)