/**
 * 模块名称: 请假外出
 * @author lids@372163.com
 */

 import React, { useState, useEffect, useRef } from 'react'
// import { useSelector } from 'react-redux'
import api from 'api'
import moment from 'moment'
import { Link } from 'react-router-dom'
import {
  Table,
  Form,
  Alert,
  Icon,
  Drawer,
  Spin,
  Input,
  Button,
  Select,
  Modal,
  message,
  Popconfirm,
  DatePicker,
  Tooltip,
  Switch
} from 'antd'
import CorpFilter from 'components/CorpFilter'
import Export from 'components/Export'
import Auth from 'components/AuthMiddleware'
import Addnewask from './module/Addnewask'
import EditLong from './module/EditLong'
import { parseSearch } from 'utils'
import CryptoJS from 'crypto-js'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
const { RangePicker } = DatePicker

let pageSize = 10
let currentPage = 1
let corpId = []
let searchParamsTmp = {
  limit: pageSize, 
  page: currentPage,
  corpId: corpId
}
let storeFilterName = ''
let storeFilterContent = {}
let apiOperateTimer = true
let shouldMe = null
let allFilter = {}

const Askforleave = (props) => {
  const { history, match, location } = props

  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form

  const [filterName, setFilterName] = useState(undefined)

  const [count, setCount] = useState(0)
  const [leaveNum, setLeaveNum] = useState({})
  const [list, setList] = useState([])
  const productsRef = useRef()
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  
  const [drawerLoading, setDrawerLoading] = useState(true)

  const [departmentList, setDepartmentList] = useState([])
  const [askTypeList, setAskTypeList] = useState([])
  const [filterModelvisible, setFilterModelvisible] = useState(false)
  const [filterContent, setFilterContent] = useState([])
  const [statusList, setStatusList] = useState([])
  const [showAll, setShowAll] = useState(false)
  const [shouldMeDis, setShouldMeDis] = useState(true)//风控，防止用户频繁点击switch
  
  const [authList, setAuthList] = useState([])//按钮权限
  const [showExport, setShowExport] = useState(false)

  //const userInfo = useSelector(state => state.userInfo)

  const [addAskType, setAddAskType] = useState(0)
  const [editId, setEditId] = useState(null)
  const [statusValName, setStatusValName] = useState(undefined)
  const [flag, setFlag] = useState(false)
  const search = parseSearch(props.location.search)
  const [timeVisible, setTimeVisible] = useState(false)
  const [timeData, setTimeData] = useState('')
  
  useEffect(() => {
    // console.log(history.location.state)
    if (!history.location.state) {
      currentPage = search.page ? +search.page : 1
      pageSize = search.limit ? +search.limit : 10
      searchParamsTmp = {
        limit: pageSize, 
        page: currentPage
      }
    } else {
      currentPage = search.page ? +search.page : currentPage
      pageSize = search.limit ? +search.limit : pageSize
      commonality()
      // console.log(searchParamsTmp)
      setTimeout(() => {
        setFieldsValue({
          section: searchParamsTmp.section ? searchParamsTmp.section : undefined,
          no: searchParamsTmp.no ? searchParamsTmp.no : undefined,
          name: searchParamsTmp.name ? searchParamsTmp.name : undefined,
          ask: searchParamsTmp.ask ? searchParamsTmp.ask : undefined,
          long: searchParamsTmp.long ? searchParamsTmp.long : undefined,
          status: searchParamsTmp.status ? searchParamsTmp.status : undefined,
          onpicker: searchParamsTmp.sTime ? [moment(searchParamsTmp.sTime), moment(searchParamsTmp.eTime)] : undefined,
          inpicker: searchParamsTmp.sDate ? [moment(searchParamsTmp.sDate), moment(searchParamsTmp.eDate)] : undefined,
          lead: searchParamsTmp.lead ? searchParamsTmp.lead: undefined
        })
      }, 1000)
    }
    shouldMe = null
    apiOperateTimer = true
    storeFilterName = ''
    storeFilterContent = {}
    getLeaveOutList()
    getPageAuth()
  },[])

  //公共接口
  const commonality = () => {
    api.getDepartment(corpId.length ? {limit: global.paramsLimit, companyId: corpId} : {limit: global.paramsLimit}).then(res => setDepartmentList(res.list))//部门
    api.getLeaveOutAskType({ source: 1 }).then(res => setAskTypeList(res.list))//请假类型
    api.getLeaveOutStatus().then(res => setStatusList(res.list))//申请状态
  }

  //按钮权限
  const getPageAuth = () => {
    api.getPageAuth().then(list => {
      setAuthList(list)
    })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  //点击选择所属公司
  const onChangeCorp = (data) => {
    //console.log('onChangeCorp', data)
    setDepartmentList([])
    setFieldsValue({'section': undefined})
    currentPage = 1
    if(data.length) {
      let arr = []
      data.forEach(item => {
        arr.push(item.id)
      })
      corpId = [].concat(arr)
      searchParamsTmp = {
        limit: pageSize, 
        page: currentPage,
        corpId: corpId
      }
    }else{
      corpId = []
      searchParamsTmp = {
        limit: pageSize, 
        page: currentPage
      }
    }
    history.replace(match.path)
    getLeaveOutList()
  }
  //点击重置
  const onReset = () => {
    currentPage = 1
    corpId.length ? searchParamsTmp = {
      limit: pageSize, 
      page: currentPage,
      corpId: corpId
    } : searchParamsTmp = {
      limit: pageSize, 
      page: currentPage
    }
    shouldMe = null
    setStatusValName(undefined)
    resetFields()
    history.replace(match.path)
    getLeaveOutList()
  }

  //分页点击
  const onChangeTable = (pagination, filters, sorter) => {
    searchParamsTmp.page = currentPage = pagination.current
    searchParamsTmp.limit = pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getLeaveOutList()
  }

  //删除请假外出申请
  const onDelete = (id) => {
    api.onLeaveOutDel({id}, true).then(res => {
      message.success('删除成功')
      getLeaveOutList()
    })
  }
  //编辑
  const onEdit = (record) => {
    setVisible(true)
    setAddAskType(1)
    setEditId(record.id)
  }

  // 修改时长
  const onEditTime = (val) => {
    setTimeVisible(true)
    setTimeData(val)
  }

  //根据职工获取角色列表
  const getLeaveOutList = () => {
    if(apiOperateTimer){
      setLoading(true)
      if(corpId.length)searchParamsTmp.corpId = corpId
      if(shouldMe){
        searchParamsTmp.shouldMe = shouldMe
      }else{
        for(let i in searchParamsTmp){
          if(i == 'shouldMe')delete searchParamsTmp[i]
        }
      }
      api.getLeaveOutList(searchParamsTmp).then(res => {
        setCount(res.count)
        setList(res.list)
        setLeaveNum(res.leaveNum)
        setLoading(false)
      }).catch(() => {
        setLoading(false)
      })
      apiOperateTimer = false
      setShouldMeDis(false)
      let timer = setTimeout(() => {
        apiOperateTimer = true
        setShouldMeDis(true)
        clearTimeout(timer)
      }, 1000)
    }else{
      return message.info('请不要频繁操作')
    }
  }
  //关闭角色授权弹窗
  const closeDrawer = () => {
    setVisible(false)
  }
  //获取部门列表
  const getDepartmentList = () => {
    api.getDepartment(corpId.length ? {limit: global.paramsLimit, companyId: corpId} : {limit: global.paramsLimit}).then(res => {
      setDepartmentList(res.list)
    })
  }
  //获取请假类型列表
  const getLeaveOutAskType = () => {
    api.getLeaveOutAskType({ source: 1 }).then(res => {
      setAskTypeList(res.list)
    })
  } 
  // 获取申请状态
  const getLeaveOutStatus = () => {
    api.getLeaveOutStatus().then(res => {
      setStatusList(res.list)
    })
  }
  //数据转换
  const transData = (vals) => {
    if(vals !== undefined){
      if(vals.no !== undefined)searchParamsTmp.no = vals.no
      if(vals.name !== undefined)searchParamsTmp.name = vals.name
      if(vals.section !== undefined)searchParamsTmp.section = vals.section
      if(vals.lead !== undefined)searchParamsTmp.lead = vals.lead
      if(vals.ask !== undefined)searchParamsTmp.ask = vals.ask
      if(vals.long !== undefined)searchParamsTmp.long = vals.long
      if(vals.status !== undefined)searchParamsTmp.status = vals.status
      if(vals.onpicker !== undefined){
        searchParamsTmp.sTime = moment(vals.onpicker[0]).format('YYYY-MM-DD')
        searchParamsTmp.eTime = moment(vals.onpicker[1]).format('YYYY-MM-DD')
      }
      if(vals.inpicker !== undefined){
        searchParamsTmp.sDate = moment(vals.inpicker[0]).format('YYYY-MM-DD')
        searchParamsTmp.eDate = moment(vals.inpicker[1]).format('YYYY-MM-DD')
      }
    }
  }
  //请假申请
  const onAddRole = () =>{
    setEditId(null)
    setAddAskType(0)
    setVisible(true)
  }
  //点击筛选
  const searchSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      //console.log(vals)
      transData(vals)
      if (!err) {
        searchParamsTmp.page = currentPage = 1
        history.replace(match.path)
        getLeaveOutList()
        setFilterName(undefined)
      }
    })
    
  }
  const onSetShouldMe = e => {
    if(e == true){
      shouldMe = 1
    }else{
      shouldMe = null
    }
    getLeaveOutList()
  }
  //选择保存方案
  const onChangeGetFilter = (val) => {
    val ? setFlag(true) : setFlag(false)
    // 下拉框搜索内容   
    val && api.getSearchStorage({key: location.pathname}).then(res => {
      setFilterContent(res.list)
    })
  }
  //选择方案
  const onFilterChecked = (item, val2) => {
    setFilterName(item)
    setStatusValName(val2.props.children.props.children[0])
    // let data = JSON.parse(item)
    let data = filterContent[item].content
    searchParamsTmp.no = data.no
    searchParamsTmp.name = data.name
    searchParamsTmp.section = data.section
    searchParamsTmp.lead = data.lead
    searchParamsTmp.ask = data.ask
    searchParamsTmp.long = data.long
    searchParamsTmp.status = data.status
    searchParamsTmp.sTime = data.sTime
    searchParamsTmp.eTime = data.eTime
    searchParamsTmp.sDate = data.sDate
    searchParamsTmp.eDate = data.eDate
    setFieldsValue({
      no: data.no ? data.no : undefined,
      name: data.name ? data.name : undefined,
      section: data.section ? data.section : undefined,
      lead: data.lead ? data.lead : undefined,
      ask: data.ask ? data.ask : undefined,
      long: data.long ? data.long : undefined,
      status: data.status ? data.status : undefined,
      onpicker: data.onpicker ? [moment(data.onpicker[0]), moment(data.onpicker[1])] : undefined,
      inpicker: data.inpicker ? [moment(data.inpicker[0]), moment(data.inpicker[1])] : undefined
    })
    getDepartmentList()
    getLeaveOutAskType()
    getLeaveOutStatus()
  }

  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: location.pathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }
  
  //保存筛选条件
  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      //console.log(vals)
      if (!err) {
        const values = Object.values(vals)
        if (values.some(item => item === '' || item !== undefined)) {
          storeFilterContent = {}
          for (let k in vals) {//eslint-disable-line
            if (vals[k] !== undefined) {
              if (k === 'inpicker' || k === 'onpicker') {
                storeFilterContent[k] = [moment(vals[k][0]).format('YYYY-MM-DD'), moment(vals[k][1]).format('YYYY-MM-DD')]
              } else {
                storeFilterContent[k] = vals[k]
              }
            }
          }
          setFilterModelvisible(true)
        } else {
          message.error('请选择筛选内容')
        }
      }
    })
  }
  //保存筛选点击确定
  const filterHandleOk = () => {
    validateFields((err, vals) => {   
      api.saveSearchStorage({
        name: storeFilterName,
        key: location.pathname,
        content: storeFilterContent
      }).then(() =>{
        setFilterModelvisible(false)
        message.success('添加成功')
      })
    })
  }
  //取消筛选点击确定
  const filterHandleCancel = () => {
    setFilterModelvisible(false)
  }
  //修改筛选名称
  const onFilterInfoChange = (e) => {
    storeFilterName = e.target.value
  }

  //导出 
  const onExportFile = () => {
    setShowExport(true)
    allFilter = Object.assign({}, searchParamsTmp)
    for (let i in allFilter) {
      if (i == 'page' || i == 'limit') delete allFilter[i]
    }
    if (allFilter.corpId && allFilter.corpId.length > 0) {
      allFilter.corpId = allFilter.corpId.join(',')
    } else {
      allFilter.corpId = ''
    }
  }

  return (
    <>
      <CorpFilter onChange={onChangeCorp} />
      <div className="search-askforleave-wrap">
        <h4 className="title">其它选项：</h4>
        {
          !showAll ? <span className="askforleave-filter-control" onClick={() => setShowAll(true)}>展开<Icon type="down" /></span>
          : <span className="askforleave-filter-control" onClick={() => setShowAll(false)}>收起<Icon type="up" /></span>
        }
        <Form onSubmit={searchSubmit} {...formItemLayout}>
          <div className={`form-box${showAll ? ' askforleave-form-auto' : ''}`}>
            <FormItem label="员工编号">
              {getFieldDecorator('no')(<Input 
                placeholder="请输入员工编号" 
              />)}
            </FormItem>
            <FormItem label="公司用名">
              {getFieldDecorator('name')(<Input 
                placeholder="请输入公司用名" 
              />)}
            </FormItem>
            <FormItem label="部门">
              {getFieldDecorator('section')(<Select
                placeholder="全部" 
                dropdownMatchSelectWidth={false}
                onDropdownVisibleChange={bool => bool && getDepartmentList()}
                showSearch
                optionFilterProp="children"
                // notFoundContent={departmentList.length > 0 ? null : <Spin size="small" />}
              >
                {
                  departmentList.map(item => {
                    return !corpId.length ? <Option title={item.name + '（' + item.companyName + '）'} key={item.id}>{item.name}（{item.companyName}）</Option> : <Option title={item.name} key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="直接主管">
              {getFieldDecorator('lead')(<Input 
                placeholder="请输入直接主管" 
              />)}
            </FormItem>
            <FormItem label="请假类型">
              {getFieldDecorator('ask')(<Select
                placeholder="全部" 
                onDropdownVisibleChange={bool => bool && getLeaveOutAskType()}
              >
                {
                  askTypeList.map(item => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="时长">
              {getFieldDecorator('long')(<Input 
                placeholder="请输入请假时长" 
              />)}
            </FormItem>
            <FormItem label="开始时间">
              {getFieldDecorator('inpicker', {
              })(<RangePicker allowClear={false} />)}
            </FormItem>
            <FormItem label="申请时间">
              {getFieldDecorator('onpicker', {
              })(<RangePicker allowClear={false} />)}
            </FormItem>
            <FormItem label="申请状态">
              {getFieldDecorator('status')(<Select
                placeholder="全部" 
                onDropdownVisibleChange={bool => bool && getLeaveOutStatus()}
                dropdownMatchSelectWidth={false}
                showSearch
                optionFilterProp="children"
                // notFoundContent={statusList.length > 0 ? null : <Spin size="small" />}
              >
                {
                  statusList.map(item => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
          </div>
          <FormItem className="btns" label=" " colon={false}>
            <div className="staff-filter-btns">
              {showAll && <>
                <h4 className="filter-title">已保存筛选方案：</h4>
                <div className="filter-select">
                  <Select style={{display: 'inline-block', width: '160px'}} placeholder="请选择" onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)} onSelect={onFilterChecked} value={filterName}> 
                    {
                      filterContent.map((item, index) =>
                      <Option value={index} key={index}>
                        <div className="closeStyBtn">
                          {item.name}
                          {flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                            e.stopPropagation()
                            onSearchDelete(item.id)
                          }} /></span> : null}
                        </div>
                      </Option>
                    )
                    }
                  </Select>
                </div>
                <Button className="filter-savebtn" onClick={onSaveFilterTerm}>保存筛选条件</Button>
              </>}
              <Switch 
            checkedChildren="待审批" 
            unCheckedChildren="待审批" 
            disabled={!shouldMeDis} 
            onClick={onSetShouldMe} 
            className="shouldme-switch-btn" 
            checked={shouldMe ? true : false} 
          />
              <Button onClick={onReset}>重置</Button>
              <Button type="primary" htmlType="submit">筛选</Button>
              <Modal
                title="保存筛选条件"
                visible={filterModelvisible}
                onOk={filterHandleOk}
                onCancel={filterHandleCancel}
              >
                <Input placeholder="请输入筛选名称" onChange={onFilterInfoChange}/>
              </Modal>
            </div>
          </FormItem>   
        </Form>
      </div>
      <div className="line"></div>
      <Export
        show={showExport}
        onCancel={() => {
          setShowExport(false)
          productsRef.current.changeVal()
        }}
        tplUrl='getLeaveOutChangeExportTpl'
        fieldsUrl='getLeaveOutChangeTplItems'
        saveUrl='saveLeaveOutChangeExportTpls'
        exportUrl='exportLeaveOutChange'
        method="export"
        parame={{ ExportType: 'leaveout.export', ...allFilter }}
        cRef={productsRef}
      />
      <div className="add-wrap">
        <Auth auths={authList} code="export">
          <Button type="primary" title="导出" style={{marginRight: 8}} onClick={onExportFile}>导出</Button>
        </Auth>
        <Auth auths={authList} code="staff-attendance-apply">
          <Button type="primary" title="请假申请" icon="plus" onClick={onAddRole}>请假申请</Button>
        </Auth>
      </div>
      {!loading && leaveNum && <Alert className="corp-count" message={`剩余年假：去年未休年假 ${leaveNum.lastNum} 天，今年未休年假 ${leaveNum.nowNum} 天。如有疑问请联系行政部。本年度剩余事假天数：${leaveNum.casualNum} 天。总计：${count}条数据`} type="info" showIcon />}
      <Table
        size="middle"
        dataSource={list}
        rowKey="id"
        loading={loading}
        className="askforleave-table"
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        onChange={onChangeTable}
        scroll={{ x: 1200 }}
      >
        <Column title="员工编号" dataIndex="no" width={140} fixed="left"/>
        <Column title="姓名" dataIndex="userName" width={120} fixed="left" render={(text, record) =>
          <>
            {
              record.askType == 13 ? 
              <Link to={`${match.path}/addaskdetails?id=${record.id}&new=1&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{text}</Link> :
              <Link to={`${match.path}/addaskdetail?id=${record.id}&new=1&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{text}</Link>
            }
          </>
        } /> 
        <Column title="部门" dataIndex="sectionName" width={180} />
        <Column title="职级" dataIndex="jobPositionCn" width={150} />
        <Column title="员工状态" dataIndex="statusName" width={150} />
        <Column title="请假类型" dataIndex="ask" width={150} />
        <Column title="申请状态" dataIndex="auditName" width={150} />
        <Column title="开始时间" width={220} render={(text, record) => {
          return <>{record.sDate}&nbsp;{record.sTime}</>
        }} />
        <Column title="时长" dataIndex="long" width={100} />
        <Column title="修改时长" dataIndex="editLong" width={120} />
        <Column title="申请时间" dataIndex="addTime"width={220} />
        <Column title="操作" width={120} key="set" fixed="right" render={(text, record) => (
          <>
            <Tooltip title="在新页面打开详情页" placement="bottom">
              {
                record.askType == 13 ? <Link target='_blank' to={`${match.path}/addaskdetails?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>
                <Icon type="folder-open" style={{ color: '#1890FF', fontSize: '16px', marginLeft: '8px' }} />
                </Link> :  <Link target='_blank' to={`${match.path}/addaskdetail?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>
                  <Icon type="folder-open" style={{ color: '#1890FF', fontSize: '16px', marginLeft: '8px' }} />
                </Link>
              }
            </Tooltip>
            {text.editFlag && 
            <Tooltip title="编辑" placement="bottom">
              <Icon className="operate-icon" style={{ color: '#1890ff' }} type="edit" onClick={() => onEdit(record)} />
            </Tooltip>
            }
            {' '}
            {text.delFlag && <Popconfirm title="请确认是否删除？" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
              <Tooltip title="删除" placement="bottom">
                <Icon className="operate-icon" style={{ color: 'red' }} type="delete" />
              </Tooltip>
            </Popconfirm>}
            {text.editTimeFlag && 
             <Tooltip title="修改时长" placement="bottom">
               <Icon className="operate-icon" style={{ color: '#1890ff' }} type="clock-circle" onClick={() => onEditTime(record)} />
            </Tooltip>
            }
          </>
        )} />
      </Table>
      <Drawer
        title="请假申请"
        width={800}
        onClose={closeDrawer}
        visible={visible}
        destroyOnClose={true}
      >
        <Spin spinning={drawerLoading}>
          <Addnewask
            setVisible={setVisible}
            getLeaveOutList={getLeaveOutList}
            setDrawerLoading={setDrawerLoading}
            leaveNum={leaveNum}
            addAskType={addAskType}
            editId={editId}
          />
        </Spin>
      </Drawer>
      <Modal
        title="修改时长"
        visible={timeVisible}
        onCancel={() => setTimeVisible(false)}
        destroyOnClose={true}
        footer={[]}
      >
        <EditLong 
          timeVisible={timeVisible}
          getLeaveOutList={getLeaveOutList}
          timeData={timeData}
          setTimeVisible={setTimeVisible}
        />
      </Modal>
    </>
  )
}

export default Form.create()(Askforleave)