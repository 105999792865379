/**
 * 模块名称: 全员考勤
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from 'api'
import {
  Table,
  Form,
  Icon,
  Tooltip,
  message,
  Select,
  Radio,
  Button,
  Drawer,
  Tabs,
  Spin,
  TimePicker,
  Alert,
  Checkbox
} from 'antd'
import { parseSearch } from 'utils'
import CorpMore from 'components/CorpMore'
import WholeFilter from 'components/WholeFilter'
import Auth from 'components/AuthMiddleware'
import BtnGroup from 'components/BtnGroup'
import Import from 'components/Import'
import Export from 'components/Export'
import moment from 'moment'
// import { redBright } from 'ansi-colors'
import './assets/style.scss'

const { Column } = Table
const { TabPane } = Tabs
const { Option } = Select
const FormItem = Form.Item
const format = 'HH:mm';
let pageSize = 10
let currentPage = 1

//保存筛选初始值
let params = {

}

//筛选初始值
let exporfilter = {
  corpId: '',
  no: '',
  name: '',
  section: '',
  lead: '',
  ask: '',
  checkNum: '',
  sTime: '',
  eTime: ''
}

let selectedIds = ''

let outAskTime = ['1', '0', '0.5', '0.5,0.5', '0,0.5', '0.5,0']
const sTime = ['上午', '下午']

const Whole = (props) => {
  const productsRef = useRef()
  const ImportRef = useRef()
  const cRef = useRef()
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, setFieldsValue } = props.form
  let locationPathname = location.pathname

  const search = parseSearch(location.search)
  currentPage = search.page ? +search.page : 1
  pageSize = search.limit ? +search.limit : 10

  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  // const [listGoOut, setListGoOut] = useState([])
  // const [countGoOut, setCountGoOut] = useState(0)
  const [visible1, setVisible1] = useState(false)
  const [visible2, setVisible2] = useState(false)
  const [visible3, setVisible3] = useState(false)
  const [visible, setVisible] = useState(false)
  const [authList, setAuthList] = useState([])
  const [visibleFile, setVisibleFile] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)//eslint-disable-line
  const [formLoading, setformLoading] = useState(false)//eslint-disable-line
  const [formLoadings, setformLoadings] = useState(false)//eslint-disable-line
  const [leaveOutAskType, setLeaveOutAskType] = useState([])
  const [attendanceId, setAttendanceId] = useState('')
  const [disabled, setdisabled] = useState(false)
  const [longStyle, setlongStyle] = useState(false)
  const [multiple, setmultiple] = useState(false)
  const [disabledAsk, setdisabledAsk] = useState(false)
  const [activeKeys, setactiveKeys] = useState('')
  const [dataTime, setdataTime] = useState({})
  const [isTypeTime, setisTypeTime] = useState('1')//eslint-disable-line
  const [WeChatList, setWeChatList] = useState([])
  const [showExport, setShowExport] = useState(false)
  const [showExports, setShowExports] = useState(false)
  const [selectedCount, setSelectedCount] = useState(0)//eslint-disable-line
  const [dataType, setDataType] = useState('0')
  const [drawerShow, setDrawerShow] = useState(true)
  const [imSection, setImSection] = useState(0)
  const [askId, setAskId] = useState('')

  useEffect(() => {
    exporfilter = {
      corpId: '',
      no: '',
      name: '',
      section: '',
      lead: '',
      ask: '',
      checkNum: '',
      sTime: '',
      eTime: ''
    }
    getCheckInGetAllList() //全员考勤列表接口
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  //全员考勤列表
  const getCheckInGetAllList = () => {
    setLoading(true)
    api.getCheckInGetAllList({
      limit: pageSize,
      page: currentPage,
      corpId: exporfilter.corpId,
      name: exporfilter.name
    }).then(data => {
      setList(data.list)
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  //筛选时调接口
  const getWholeList = () => {
    setLoading(true)
    api.getCheckInGetAllList({
      limit: pageSize,
      page: currentPage,
      corpId: exporfilter.corpId,
      name: exporfilter.name,
      no: exporfilter.no,
      section: exporfilter.section,
      lead: exporfilter.lead,
      ask: exporfilter.ask,
      checkNum: exporfilter.checkNum,
      sTime: exporfilter.sTime,
      eTime: exporfilter.eTime
    }).then(data => {
      setList(data.list)
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  //微信打卡接口
  const getCheckInWxGetGoOutList = () => {
    api.getCheckInWxGetGoOutList({
      limit: pageSize,
      page: currentPage,
      corpId: exporfilter.corpId,
      no: exporfilter.no,
      name: exporfilter.name,
      section: exporfilter.section,
      lead: exporfilter.lead,
      ask: '',
      checkNum: exporfilter.checkNum,
      sTime: exporfilter.sTime,
      eTime: exporfilter.eTime
    }).then(data => {
      // setListGoOut(data.list)
      // setCountGoOut(data.count)
      setLoading(false)
      setdisabledAsk(true)
    }).catch(() => setLoading(false))
  }

  //点击时筛选
  const formFilter = (vals) => {
    //const values = Object.values(vals)
    currentPage = 1
    exporfilter.name = vals.name ? vals.name : ''
    exporfilter.no = vals.no ? vals.no : ''
    exporfilter.section = vals.section ? vals.section : ''
    exporfilter.lead = vals.lead ? vals.lead : ''
    exporfilter.ask = vals.ask ? vals.ask : ''
    exporfilter.checkNum = vals.checkNum ? vals.checkNum : ''
    exporfilter.sTime = vals.time ? vals.time[0].format('YYYY-MM-DD') : ''
    exporfilter.eTime = vals.time ? vals.time[1].format('YYYY-MM-DD') : ''
    history.replace(match.path)
    activeKeys === '2' ? getCheckInWxGetGoOutList() : getWholeList()
  }

  //保存筛选
  const saveFilterTerm = (vals) => {
    const values = Object.values(vals)
    if (values.some(item => item !== undefined)) {
      params = {}
      for (let k in vals) {//eslint-disable-line
        if (vals[k] !== undefined) {
          if (k === 'time') {
            params[k] = [moment(vals[k][0]).format('YYYY-MM-DD'), moment(vals[k][1]).format('YYYY-MM-DD')]
          } else {
            params[k] = vals[k]
          }
        }
      }
      setVisible(true)
    } else {
      message.error('当前没有设置筛选条件')
    }
  }

  //点击保存筛选弹出框内确定按钮
  const handleOk = (filterInfoValue) => {
    api.saveSearchStorage({
      name: filterInfoValue,
      key: location.pathname,
      content: params
    }).then(data => {
      setVisible(false)
      message.success('添加成功')
    })
  }

  //点击保存筛选弹出框内取消按钮
  const handleCancel = () => {
    setVisible(false)
  }

  //重置
  const onFilterReset = () => {
    exporfilter.no = ''
    exporfilter.name = ''
    exporfilter.section = ''
    exporfilter.lead = ''
    exporfilter.ask = ''
    exporfilter.checkNum = ''
    exporfilter.sTime = ''
    exporfilter.eTime = ''
    currentPage = 1
    history.replace(match.path)
    activeKeys === '2' ? getCheckInWxGetGoOutList() : getCheckInGetAllList()
  }

  //编辑
  const onEdit = (data) => {
    setisTypeTime('2')
    setVisible2(true)
    setDrawerShow(false)
    setdataTime(data)
    setTimeout(() => {
      setFieldsValue({
        signIn: data.signInOrg ? moment(data.signInOrg, 'HH:mm') : '',
        signOut: data.signOutOrg ? moment(data.signOutOrg, 'HH:mm') : ''
      })
    }, 0)
  }

  //编辑类型
  const onEditType = (data) => {
    setformLoadings(true)
    let arr = data.ask.split(',')
    if (arr.length === 1) {
      setAskId(+data.ask)
    } else if(arr.some(v => v == 7)) {
      let val = arr.filter(v => v == 7)[0]
      setAskId(+val)
    }
    setisTypeTime('1')
    setVisible1(true)
    setDataType(data.long)
    setDrawerShow(true)
    setAttendanceId(data.id)
    let ask = (data.ask).split(",")
    if (ask.length > 1) {
      setmultiple(true)
      setdisabled(false)
      setlongStyle(false)
    } else if (data.long == '0.5') {//eslint-disable-line
      setlongStyle(true)
      setdisabled(false)
      setmultiple(false)
    } else if (data.long == '0') {//eslint-disable-line
      setdisabled(true)
      setlongStyle(false)
      setmultiple(false)
    } else if (data.long == '1') {//eslint-disable-line
      setlongStyle(false)
      setdisabled(false)
      setmultiple(false)
    }
    if (data.long !== '0,0.5' && data.long !== '0.5,0' && +data.long !== 0) {
      api.getLeaveOutAskType({ type: '1' }).then((res) => {
        let arr = res.list.filter(v => v.id !==12 && v.id !==15)
        setLeaveOutAskType(arr)
      })
    } else {
      api.getLeaveOutAskType({ type: '1' }).then(res => setLeaveOutAskType(res.list))
    }
    setTimeout(() => {
      if (+data.long === 0.5 && data.sTime) {
        let str= data.sTime.substring(0,2)
        setFieldsValue({ sTime: str })
      }
      // console.log(ask.some(v => v == 7))
      if (ask.some(v => v == 7) && data.signType) {
       setFieldsValue({ signType: data.signType.split(',') })
      }
      setFieldsValue({
        long: data.long,
        ask: data.ask === '' ? data.ask : ask.map((item) => +item),
      })
      setformLoadings(false)
    }, 500)
  }

  //所属公司的筛选
  const onChangeTable = (pagination) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getWholeList()
  }

  const rowSelection = {//eslint-disable-line
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedCount(selectedRows.length)
      selectedIds = selectedRowKeys
    }
  }

  //导入
  const onImportFile = () => {
    if (!exporfilter.corpId || exporfilter.corpId == '') {
      message.error("请选择导入的考勤表中员工所在公司的页签")
      return
    }
    setVisibleFile(true)
    // onRemoved就是子组件暴露给父组件的方法
    ImportRef.current.onRemoved()
  }

  //点击确定导入
  const onSearch = (fileList) => {
    if (fileList.length > 0) {
      if (fileList[0].status !== "removed") {
        api.setCheckInHandle({
          file: fileList[0].url,
          corpId: exporfilter.corpId
        }).then(data => {
          message.success('导入成功，请到导入列表查看。')
          ImportRef.current.onLoading()
          setVisibleFile(false)
        }).catch(data => {
          ImportRef.current.onLoading()
        })
      } else {
        message.error('请导入模板')
      }
    } else {
      message.error('请导入模板')
    }
  }

  //点击导入按钮
  const onCloseImport = () => {
    setVisibleFile(false)
  }

  const onExportFile = () => {
    setShowExport(true)
  }

  //行政导出
  const onExportFiles = () => {
    setShowExports(true)
  }

  //筛选
  const onCorpFilter = (data) => {
    data.length ? setImSection(imSection + 1) : setImSection(0)
    exporfilter.corpId = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getCheckInGetAllList()
  }

  //tabs切换的回调函数
  const onChangeTabs = (activeKey) => {
    setactiveKeys(activeKey)
    currentPage = 1
    exporfilter = {
      corpId: '',
      no: '',
      name: '',
      section: '',
      lead: '',
      ask: '',
      checkNum: '',
      sTime: '',
      eTime: ''
    }
    if (activeKey === '2') {
      setLoading(true)
      cRef.current.changeVal()
      getCheckInWxGetGoOutList()
    } else {
      cRef.current.changeVal()
      getCheckInGetAllList()
      setdisabledAsk(false)

    }
  }

  const afterVisibleChangeType = (isOpen) => {
    if (isOpen) {

    }
  }

  const afterVisibleChangeTime = (isOpen) => {
    if (isOpen) {

    }
  }

  const afterVisibleChangeInfo = (isOpen) => {
    if (isOpen) {

    }
  }

  // 提交添加
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      //console.log(vals)
      if (!err) {
        console.log(vals)
        if (vals.ask.length > 2) {
         return message.warning('请正确选择请假类型')
        }
        api.setCheckInUpdateLeaveOut({
          id: attendanceId,
          long: vals.long === '0.5,0.5' ? '1' : vals.long === '0.5,0' || vals.long === '0,0.5' ? '0.5' : vals.long,
          ask: Array.isArray(vals.ask) ? (vals.ask).join(',') : vals.ask,
          time: vals.long == '0.5,0.5' || vals.long == '1' || vals.long === '0.5,0' ? '上午' : vals.long === '0,0.5' ? '下午' : vals.sTime,
          signType: askId === 7 ? vals.signType : ''
        }).then(data => {
          message.success('修改成功')
          setAskId('')
          setVisible1(false)
          getWholeList()
        })
      }
    })
  }

  //选中请假时长的判断
  const onSelectLong = (value) => {
    setDataType(value)
    if (value !== '0,0.5' && value !== '0.5,0') {
      api.getLeaveOutAskType({ type: '1' }).then((data) => {
        let arr = data.list.filter(v => v.id !==12 && v.id !==15)
        setLeaveOutAskType(arr)
      })
    } else {
      api.getLeaveOutAskType({ type: '1' }).then(data => setLeaveOutAskType(data.list))
    }
    setAskId('')
    if (value === '0') {
      setdisabled(true)
      setlongStyle(false)
      setmultiple(false)
      setFieldsValue({
        ask: ''
      })
    } else if (value === '0.5') {
      setlongStyle(true)
      setdisabled(false)
      setmultiple(false)
      setFieldsValue({
        ask: ''
      })
    } else if (value === '0.5,0.5') {
      setmultiple(true)
      setlongStyle(false)
      setdisabled(false)
      setFieldsValue({
        ask: []
      })
    } else if (value === '1') {
      setdisabled(false)
      setlongStyle(false)
      setmultiple(false)
      setFieldsValue({
        ask: ''
      })
    } else if (value === '0,0.5' || value === '0.5,0') {
      setmultiple(true)
      setdisabled(false)
      setlongStyle(false)
      setFieldsValue({
        ask: []
      })
    }
  }

  const formSubmit1 = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        //console.log(vals)
        api.setCheckInUpdateCheckInRecord({
          checkInId: dataTime.id,
          signIn: vals.signIn === null || vals.signIn === "" ? '' : moment(vals.signIn).format('HH:mm'),
          signOut: vals.signOut === null || vals.signOut === "" ? '' : moment(vals.signOut).format('HH:mm')
        }).then(data => {
          setVisible2(false)
          getCheckInGetAllList()
        })
      }
    })
  }

  const onEditSourceName = (data) => {
    setVisible3(true)
    api.getCheckInGetWxCheckInData({
      id: data.userId,
      date: data.date
    }).then(data => {
      setWeChatList(data)
    })
  }

  const onSelectType = (value) => {
    setAskId(value)
  }

  //多选请假类型
  const onSelectTypes = (value) => {
    const arr = value.filter(v => v === 7)
    if (arr.length > 0) {
      setAskId(arr[0])
    } else {
      setAskId('')
    }
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  return (
    <div className="mployee-wrap">
      <CorpMore onChange={onCorpFilter} />
      <WholeFilter
        formFilter={formFilter}
        saveFilterTerm={saveFilterTerm}
        handleOk={handleOk}
        handleCancel={handleCancel}
        visible={visible}
        locationPathname={locationPathname}
        onFilterReset={onFilterReset}
        disabledAsk={disabledAsk}
        activeKeys={activeKeys}
        cRef={cRef}
        section={imSection}
        corpId={exporfilter.corpId}
      />
      <div className="line"></div>
      <div className="card-container" style={{ marginTop: '20px' }}>
        <Tabs type="card" onChange={onChangeTabs}>
          <TabPane tab="考勤信息" key="1">
            <div className="add-wrap">
              <Auth auths={authList} code="xz-export">
                <Button type="primary" icon="upload" onClick={onExportFiles} style={{ marginLeft: 15 }}>行政导出</Button>
                <Export
                  show={showExports}
                  onCancel={() => setShowExports(false)}
                  tplUrl={activeKeys === '2' ? "getCheckInWxExportTpl" : "getCheckInExportTpl"}
                  fieldsUrl={activeKeys === '2' ? "getCheckInWxTplItems" : "getCheckInTplItems"}
                  saveUrl={activeKeys === '2' ? "getCheckInWxSaveExportTpls" : "getCheckInSaveExportTpls"}
                  exportUrl={activeKeys === '2' ? "exportCheckInWx" : "exportCheckIn"}
                  method={activeKeys === '2' ? "getgooutList" : "getalllist"}
                  parame={{ ExportType: activeKeys === '2' ? 'checkinwx.getgooutList' : 'checkin.getalllist', id: selectedIds, ...exporfilter, is_xz: 1 }}
                />
              </Auth>
              <Auth auths={authList} code="import">
                <Button type="primary" icon="download" onClick={onImportFile} style={{ marginLeft: 15 }}>导入</Button>
                <Import
                  onSearch={onSearch}
                  visibleFile={visibleFile}
                  onCloseImport={onCloseImport}
                  ImportTemplate="/templates/全员考勤导入模板.xlsx"
                  ImportRef={ImportRef}
                />
              </Auth>
              <Auth auths={authList} code="export">
                <Button type="primary" icon="upload" onClick={onExportFile} style={{ marginLeft: 15 }}>导出</Button>
              </Auth>
            </div>
            <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
            <Table
              size="middle"
              dataSource={list}
              rowKey="number"
              loading={loading}
              pagination={{
                pageSize,
                total: count,
                current: currentPage,
                showQuickJumper: true,
                showSizeChanger: true,
                pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
              }}
              scroll={{ x: 2200 }}
              onChange={onChangeTable}
            >
              <Column title="序号" dataIndex="id" fixed="left" width={100} render={((text, record) => (
                <>{record.child ? record.child.map((v, i) => <div key={i}>{v.id}</div>) : text}</>
              ))}/>
              <Column title="日期" dataIndex="date" fixed="left" width={150} render={((text, record) => (
                <>{record.child ? record.child.map((v, i) => <div key={i}>{v.date}</div>) : text}</>
              ))}/>
              <Column title="员工编码" dataIndex="no" fixed="left" width={150} render={((text, record) => (
                <>{record.child ? record.child.map((v, i) => <div key={i}>{v.no}</div>) : text}</>
              ))}/>
              <Column title="姓名" dataIndex="userName" fixed="left" width={150} render={((text, record) => (
                <>{record.child ? record.child.map((v, i) => <div key={i}>{v.userName}</div>) : text}</>
              ))}/>
              <Column title="员工ID" dataIndex="userId" width={150} render={((text, record) => (
                <>{record.child ? record.child.map((v, i) => <div key={i}>{v.userId}</div>) : text}</>
              ))}/>
              <Column title="考勤号" dataIndex="checkNum" width={120} render={((text, record) => (
                <>{record.child ? record.child.map((v, i) => <div key={i}>{v.checkNum}</div>) : text}</>
              ))}/>
              <Column title="部门" dataIndex="sectionName" width={240} render={((text, record) => (
                <>{record.child ? record.child.map((v, i) => <div key={i}>{v.sectionName}</div>) : text}</>
              ))}/>
              <Column title="员工状态" dataIndex='statusName' width={100} render={((text, record) => (
                <>{record.child ? record.child.map((v, i) => <div key={i}>{v.statusName}</div>) : text}</>
              ))}/>
              <Column title="签到" key="signIn" width={80} render={(text => (
                <span className={text.signInRed ? 'activeRed' : ''}>{text.signIn}</span>
              ))}/>
              <Column title="签退" key="signOut" width={120} render={(text => (
                <span className={text.signOutRed ? 'activeRed' : ''}>{text.signOut}</span>
              ))}/>
              <Column title="迟到" dataIndex="late" width={120} render={((text, record) => (
                <>{record.child ? record.child.map((v, i) => <div key={i}>{v.late}</div>) : text}</>
              ))}/>
              <Column title="早退" dataIndex="leave" width={120} render={((text, record) => (
                <>{record.child ? record.child.map((v, i) => <div key={i}>{v.leave}</div>) : text}</>
              ))}/>
              <Column title="未打卡" dataIndex="notCheck" width={120} render={((text, record) => (
                <>{record.child ? record.child.map((v, i) => <div key={i}>{v.notCheck}</div>) : text}</>
              ))}/>
              {/* <Column title="打卡无效" dataIndex="invalid" width={100} /> */}
              <Column title="请假" dataIndex="ask_text" width={140} render={((text, record) => (
                <>{record.child ? record.child.map((v, i) => <div key={i}>{v.ask_text}</div>) : text}</>
              ))}/>
              <Column title="起始时间" dataIndex="sTime" width={200} render={((text, record) => (
                <>{record.child ? record.child.map((v, i) => <div key={i}>{v.sTime}</div>) : text}</>
              ))}/>
              <Column title="时长" dataIndex="long" width={150} render={((text, record) => (
                <>{record.child ? record.child.map((v, i) => <div key={i}>{v.long}</div>) : text}</>
              ))}/>
              <Column title="应到" dataIndex="should" width={120} render={((text, record) => (
                <>{record.child ? record.child.map((v, i) => <div key={i}>{v.should}</div>) : text}</>
              ))}/>
              <Column title="直接主管" dataIndex="lead" width={120} render={((text, record) => (
                <>{record.child ? record.child.map((v, i) => <div key={i}>{v.lead}</div>) : text}</>
              ))}/>
              {/* <Column title="打卡方式" key="sourceName" width={120} render={(text, record) => (
                record.sourceFlag ? <div style={{ color: '#1890FF' }} onClick={() => onEditSourceName(record)}>{text.sourceName}</div> : <div>{text.sourceName}</div>
              )} />
              <Column title="异常" dataIndex="exception" width={80} /> */}
              <Column title="操作" key="set" fixed="right" width={100} render={(text, record) => (
                <div>
                  <Auth auths={text.operateAuth} code="attendance-edit-type">
                    <Tooltip title="修改类型" placement="bottom">
                      <Icon type="appstore" style={{ color: '#1890FF', fontSize: '18px', marginRight: '15px' }} onClick={() => onEditType(record)} />
                    </Tooltip>
                  </Auth>
                  <Auth auths={text.operateAuth} code="edit">
                    <Tooltip title="编辑" placement="bottom">
                      <Icon type="form" style={{ color: '#1890FF', fontSize: '18px' }} onClick={() => onEdit(record)} />
                    </Tooltip>
                  </Auth>
                </div>
              )} />
            </Table>
            <Export
              show={showExport}
              onCancel={() => {
                setShowExport(false)
                productsRef.current.changeVal()
              }}
              tplUrl={activeKeys === '2' ? "getCheckInWxExportTpl" : "getCheckInExportTpl"}
              fieldsUrl={activeKeys === '2' ? "getCheckInWxTplItems" : "getCheckInTplItems"}
              saveUrl={activeKeys === '2' ? "getCheckInWxSaveExportTpls" : "getCheckInSaveExportTpls"}
              exportUrl={activeKeys === '2' ? "exportCheckInWx" : "exportCheckIn"}
              method={activeKeys === '2' ? "getgooutList" : "getalllist"}
              parame={{ ExportType: activeKeys === '2' ? 'checkinwx.getgooutList' : 'checkin.getalllist', id: selectedIds, ...exporfilter }}
              cRef={productsRef}
            />
            <Drawer
              title="打卡信息"
              width={600}
              onClose={() => setVisible3(false)}
              visible={visible3}
              afterVisibleChange={afterVisibleChangeInfo}
            >
              <Spin spinning={formLoading}>
                <Table
                  dataSource={WeChatList}
                  rowKey="id"
                  className="corp-table"
                  loading={loading}
                  scroll={{ x: 1500 }}
                  pagination={false}
                >
                  <Column title="打卡方式" dataIndex="sourceName" width={150} />
                  <Column title="签到" dataIndex="signIn" width={120} />
                  <Column title="签退" dataIndex='signOut' width={180} />
                  <Column title="备注" dataIndex="note" width={200} />
                  <Column title="打卡地点" dataIndex="locationTitle" width={300} />
                  <Column title="异常" dataIndex="exception" width={80} />
                </Table>
                <Button onClick={() => setVisible3(false)} style={{ marginTop: 50, marginLeft: 240 }} type="primary">确定</Button>
              </Spin>
            </Drawer>
            {
              drawerShow ?
                <Drawer
                  title="修改类型"
                  width={600}
                  onClose={() => {
                    setAskId('')
                    setVisible1(false)
                  }}
                  visible={visible1}
                  afterVisibleChange={afterVisibleChangeType}
                  destroyOnClose={true}
                >
                  <Spin spinning={formLoadings}>
                    <Form onSubmit={formSubmit} {...formItemLayout}>
                      <FormItem label="请假时长">
                        {getFieldDecorator('long', {
                          rules: [{ required: true, message: '请选择请假时长' }]
                        })(
                          <Select placeholder="请选择" onSelect={onSelectLong} className={longStyle ? 'longStyle' : ''}>
                            {outAskTime.map(item => <Option key={item} value={item}>{item}</Option>)}
                          </Select>
                        )}
                      </FormItem>
                      {
                        longStyle ? <FormItem className="whole-leave-time">
                          {getFieldDecorator('sTime', {
                            rules: [{ required: dataType == '0.5' ? true : false, message: '请选择上下午' }]//eslint-disable-line
                          })(
                            <Radio.Group>
                              {sTime.map((v, i) => <Radio value={v} key={i}>{v}</Radio>)}
                            </Radio.Group>
                          )}
                        </FormItem> : <div></div>
                      }
                      {
                        askId === 7 && <FormItem className="whole-leave-times">
                          {getFieldDecorator('signType', {
                              rules: [{ required: true, message: '请选择' }]
                          })(
                            <Checkbox.Group style={{ width: '100%' }} >
                              <Checkbox value="签到">签到</Checkbox>
                              <Checkbox value="签退">签退</Checkbox>
                            </Checkbox.Group>
                          )}
                        </FormItem>
                      }
                      <FormItem label="请假类型">
                        {getFieldDecorator('ask', {
                          rules: [{ required: dataType == '0' ? false : true, message: '请选择请假类型' }]//eslint-disable-line
                        })(
                          multiple ?
                            <Select placeholder="请选择" disabled={disabled} mode="multiple" onChange={onSelectTypes}>
                              {leaveOutAskType.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                            </Select> : <Select placeholder="请选择" disabled={disabled} onSelect={onSelectType}>
                              {leaveOutAskType.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                            </Select>
                        )}
                      </FormItem>
                      <BtnGroup onCancel={() => setVisible1(false)} loading={saveLoading} />
                    </Form>
                  </Spin>
                </Drawer>
                :
                <Drawer
                  title="修改考勤时间"
                  width={600}
                  onClose={() => setVisible2(false)}
                  visible={visible2}
                  afterVisibleChange={afterVisibleChangeTime}
                >
                  <Spin spinning={formLoading}>
                    <div className="whole-time">
                      <div className="whole-time-name">姓名：{dataTime.userName}</div>
                      <div className="whole-time-name">日期：{dataTime.date}</div>
                      <div className="whole-time-name">考勤号：{dataTime.checkNum}</div>
                      <div className="whole-time-name">员工ID：{dataTime.userId}</div>
                    </div>
                    <Form onSubmit={formSubmit1} {...formItemLayout}>
                      <FormItem label="签到时间">
                        {getFieldDecorator('signIn', {
                          // rules: [{ required: true, message: '请选择签到时间' }]
                        })(
                          <TimePicker format={format} style={{ width: 350 }} />
                        )}
                      </FormItem>
                      <FormItem label="签退时间">
                        {getFieldDecorator('signOut', {
                          // rules: [{ required: true, message: '请选择签退时间' }]
                        })(
                          <TimePicker format={format} style={{ width: 350 }} />
                        )}
                      </FormItem>
                      <BtnGroup onCancel={() => setVisible2(false)} loading={saveLoading} />
                    </Form>
                  </Spin>
                </Drawer>
            }
          </TabPane>
          {/* <TabPane tab="外出打卡" key="2">
            <div className="add-wrap">
              <Auth auths={authList} code="export">
                <Button type="primary" icon="upload" onClick={onExportFile} style={{ marginLeft: 15 }}>导出</Button>
              </Auth>
            </div>
            <Table
              dataSource={listGoOut}
              rowKey="id"
              className="corp-table"
              loading={loading}
              pagination={{
                pageSize,
                total: countGoOut,
                current: currentPage,
                showQuickJumper: true,
                showSizeChanger: true
              }}
              scroll={{ x: 1500 }}
              onChange={onChangeTable}
            >
              <Column title="序号" dataIndex="number" fixed="left" width={100} />
              <Column title="日期" dataIndex="date" fixed="left" width={150} />
              <Column title="员工编号" dataIndex="no" fixed="left" width={150} />
              <Column title="姓名" dataIndex="userName" fixed="left" width={100} />
              <Column title="员工ID" dataIndex="userId" width={100} />
              <Column title="考勤号" dataIndex="checkNum" width={120} />
              <Column title="部门" dataIndex="sectionName" width={150} />
              <Column title="直接主管" dataIndex="lead" width={120} />
              <Column title="打卡时间" dataIndex='checkTime' width={180} />
              <Column title="备注" dataIndex="note" width={200} />
              <Column title="打卡地点" dataIndex="locationTitle" width={300} />
              <Column title="异常" dataIndex="exception" width={80} />
              <Column title="公出" dataIndex="sTime" width={120} />
            </Table>
          </TabPane> */}
        </Tabs>
      </div>
    </div >
  )
}

export default Form.create()(Whole)