/**
 * 模块名称: 考勤统计模块
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from 'api'
import {
  Table,
  Form,
  Button,
  Input,
  Icon,
  DatePicker,
  Tooltip,
  Select,
  Modal,
  message,
  Alert
} from 'antd'
import { parseSearch } from 'utils'
import CorpMore from 'components/CorpMore'
import BtnGroup from 'components/BtnGroup'
import Export from 'components/Export'
import Auth from 'components/AuthMiddleware'
import moment from 'moment'
import './assets/style.scss'

const { Column } = Table
const FormItem = Form.Item
const { Option } = Select
let pageSize = 10
let currentPage = 1
const { RangePicker, MonthPicker } = DatePicker

//筛选初始值
let exporfilter = {
  name: '',
  corpId: '',
  month: '',
  official: '',
  section: '',
  sTime: '',
  eTime: ''
}

let params = {}

let selectedIds = ''

let lastMonth = moment().subtract(1, 'months').format('YYYY-MM')

const Statistics = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, resetFields, setFieldsValue, validateFields } = props.form

  const productsRef = useRef()

  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [department, setDepartment] = useState([])
  // const [personnelType, setPersonnelType] = useState([])
  const [visible, setVisible] = useState(false)
  const [filterContent, setFilterContent] = useState([])
  const [expand, setExpand] = useState(false)
  const [filterInfoValue, setFilterInfoValue] = useState('')
  const [alterationUnfoldPack, setAlterationUnfoldPack] = useState(true)
  const [alterationTextUnfoldPack, setAlterationTextUnfoldPack] = useState(true)
  const [statusVal, setStatusVal] = useState('请选择')
  const [authList, setAuthList] = useState([])
  const [showExport, setShowExport] = useState(false)
  const [statusValName, setStatusValName] = useState(undefined)
  const [flag, setFlag] = useState(false)
  const [visibleCollect, setVisibleCollect] = useState(false)
  const [valueCollect, setValueCollect] = useState(undefined)
  const [approveLoading, setApproveLoading] = useState(false)//通过按钮的loading
  const [attendanceMonthVal, setAttendanceMonthVal] = useState('')
  const [attendanceLoading, setAttendanceLoading] = useState(false)

  const search = parseSearch(location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    exporfilter = {
      name: '',
      corpId: '',
      month: lastMonth,
      official: '',
      section: '',
      sTime: '',
      eTime: ''
    }
    setFieldsValue({ month: lastMonth ? moment(lastMonth) : undefined })
    getStatGetList()
    onCommonStaffAlteration()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  //获取考勤统计表
  const getStatGetList = () => {
    setLoading(true)
    api.getCheckInStatGetList({
      limit: pageSize,
      page: currentPage,
      name: exporfilter.name,
      corpId: exporfilter.corpId,
      month: exporfilter.month,
      official: exporfilter.official,
      section: exporfilter.section,
      sTime: exporfilter.sTime,
      eTime: exporfilter.eTime
    }).then(data => {
      setList(data.list)
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  //获取公共接口
  const onCommonStaffAlteration = () => {
    // api.getCommon({ type: 'staff_is_formal' }).then(data => setPersonnelType(data))
    api.getSelectDepartmentList({ limit: global.paramsLimit }).then(data => setDepartment(data.list)) //部门
  }

  const onChangeTable = (pagination) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getStatGetList()
  }

  const HandleCancel = () => {
    setVisible(false) //点击保存筛选弹出框内取消
  }

  const onChangeGetFilter = (val) => {
    val ? setFlag(true) : setFlag(false)
    //展开保存筛选下拉框获取存储过的搜索内容
    val && api.getSearchStorage({ key: location.pathname }).then(data => {
      setFilterContent(data.list)
    })
  }

  const onChecked = (value, val2) => {
    setStatusVal(value)
    setStatusValName(val2.props.children.props.children[0])
    let filterCont = filterContent[value].content
    setFieldsValue({
      name: filterCont.name || filterCont.name == 0 ? filterCont.name : undefined,//eslint-disable-line
      month: filterCont.month || filterCont.month == 0 ? moment(filterCont.month) : undefined,//eslint-disable-line
      official: filterCont.official || filterCont.official == 0 ? filterCont.official : undefined,//eslint-disable-line
      section: filterCont.section || filterCont.section == 0 ? filterCont.section : undefined,//eslint-disable-line
      time: filterCont.time || filterCont.time == 0 ? [moment(filterCont.time[0]), moment(filterCont.time[1])] : undefined //eslint-disable-line
    })//选择保存筛选下拉框中的内容

  }

  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: location.pathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }

  //点击筛选提交表单
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      //const values = Object.values(vals)
      //console.log(values)
      if (!err) {
        currentPage = 1
        exporfilter.name = vals.name || vals.name == 0 ? vals.name : ''//eslint-disable-line
        exporfilter.month = vals.month || vals.month == 0 ? vals.month.format('YYYY-MM') : ''//eslint-disable-line
        exporfilter.official = vals.official || vals.official == 0 ? vals.official : ''//eslint-disable-line
        exporfilter.section = vals.section || vals.section == 0 ? vals.section : ''//eslint-disable-line
        exporfilter.sTime = vals.time || vals.time == 0 ? vals.time[0].format('YYYY-MM-DD') : ''//eslint-disable-line
        exporfilter.eTime = vals.time || vals.time == 0 ? vals.time[1].format('YYYY-MM-DD') : ''//eslint-disable-line
        history.replace(match.path)
        getStatGetList()
      }
    })
  }

  const onReset = (e) => {
    e.preventDefault()
    exporfilter.name = ''
    exporfilter.month = ''
    exporfilter.official = ''
    exporfilter.section = ''
    exporfilter.sTime = ''
    exporfilter.eTime = ''
    resetFields()
    currentPage = 1
    history.replace(match.path)
    getStatGetList()
    setStatusVal('请选择')
    setStatusValName(undefined)
  }

  const toggle = () => {
    setExpand(!expand)
    setAlterationUnfoldPack(!alterationUnfoldPack)
    setAlterationTextUnfoldPack(!alterationTextUnfoldPack)
  }

  const FilterInfoChange = (e) => {
    setFilterInfoValue(e.target.value) //获取保存筛选弹框中input值
  }

  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      if (!err) {
        const values = Object.values(vals)
        if (values.some(item => item !== undefined)) {
          params = {}
          for (let k in vals) {//eslint-disable-line
            if (vals[k] !== undefined) {
              if (k === 'time') {
                params[k] = [moment(vals[k][0]).format('YYYY-MM-DD'), moment(vals[k][1]).format('YYYY-MM-DD')]
              } else if (k === 'month') {
                // console.log(typeof (moment(vals[k]).format('YYYY-MM')))
                params[k] = moment(vals[k]).format('YYYY-MM')
              } else {
                params[k] = vals[k]
              }

            }
          }
          setVisible(true)
        } else {
          message.error('当前没有设置筛选条件')
        }
      }
    })
  }

  const HandleOk = () => {
    api.saveSearchStorage({
      name: filterInfoValue,
      key: location.pathname,
      content: params
    }).then(data => {
      setVisible(false)
      message.success('添加成功')
    }) //点击保存筛选弹出框内确定的回调函数
  }

  //筛选
  const onCorpFilter = (data) => {
    setDepartment([])
    setFieldsValue({'section': undefined})
    exporfilter.section = ''
    exporfilter.corpId = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getStatGetList()
  }

  //选中所属公司后部门下拉框内容随之变化
  const onDepartmentId = () => {
    api.getSelectDepartmentList({ limit: global.paramsLimit, companyId: exporfilter.corpId }).then(data => setDepartment(data.list)) //部门
  }

  const onExportFile = () => {
    setShowExport(true)
  }

  const rowSelection = {//eslint-disable-line
    onChange: (selectedRowKeys, selectedRows) => {
      // setSelectedCount(selectedRows.length)
      selectedIds = selectedRowKeys
    }
  }

  //当月考勤汇总
  const handleOkCollect = () => {
    if (!valueCollect) {
      return message.error('请选择截止日期')
    }
    setApproveLoading(true)
    api.getCheckInStatStatCurrentMonthAttendance({ endDate: valueCollect.format('YYYY-MM-DD') }).then(res => {
      getStatGetList()
      setApproveLoading(false)
      setValueCollect(undefined)
      setVisibleCollect(false)
    }).catch(() => setApproveLoading(false))
  }

  //禁用日期
  const disabledDate = (current) => {
    let date = moment((moment().subtract(0, 'days')).format(`YYYY-MM-${0}${1}`))
    return current && date > current || moment(attendanceMonthVal).subtract(-1, 'days') <= current
  }

  //保存日期
  const onChangeCollect = (e) => {
    setValueCollect(moment(e))
  }

  //汇总当月考勤
  const attendanceMonth = () => {
    setAttendanceLoading(true)
    api.getCheckInGetLastCheckInImportDate().then(res => {
      setAttendanceMonthVal(res)
      setVisibleCollect(true)
      setAttendanceLoading(false)
    }).catch(() => setAttendanceLoading(false))
  }

  return (
    <div className="statistics">
      <CorpMore onChange={onCorpFilter} />
      <div className="search-askforleave-wrap staffalteration-warp">
        <h4 className="title">其它选项：</h4>
        {
          alterationTextUnfoldPack ? <span className="askforleave-filter-control" onClick={toggle}>展开<Icon type="down" /></span>
          : <span className="askforleave-filter-control" onClick={toggle}>收起<Icon type="up" /></span>
        }
        <Form onSubmit={formSubmit} className="statistics-form">
          <FormItem label="员工姓名">
            {getFieldDecorator('name')(<Input placeholder="请输入员工姓名" style={{ width: 160 }} />)}
          </FormItem>
          <FormItem label="考勤月份">
            {getFieldDecorator('month')(<MonthPicker style={{ width: 160 }} placeholder="考勤月份" />)}
          </FormItem>
          <FormItem label="是否转正" >
            {getFieldDecorator('official')(
              <Select placeholder="全部" style={{ width: 160 }} dropdownMatchSelectWidth={false}>
                <Option value={0}>否</Option>
                <Option value={1}>是</Option>
                {/* {
                  personnelType.map((v, i) => {
                    return <Option value={v.id} key={i}>{v.name}</Option>
                  })
                } */}
              </Select>)}
          </FormItem>
          <FormItem label="所属部门" className={alterationUnfoldPack ? "staffalteration-unfold" : ""}>
            {getFieldDecorator('section')(
              <Select 
                placeholder="全部" 
                style={{ width: 200 }} 
                dropdownMatchSelectWidth={false} 
                onDropdownVisibleChange={bool => bool && onDepartmentId()} 
                showSearch={true}
                optionFilterProp="children"
              >
                {
                  department.map((v, i) => {
                    return !exporfilter.corpId.length ? <Option title={v.name + '（' + v.companyName + '）'} key={v.id}>{v.name}（{v.companyName}）</Option> : <Option title={v.name} key={v.id}>{v.name}</Option>
                  })
                }
              </Select>)}
          </FormItem>
          <FormItem label="到职日期" className={alterationUnfoldPack ? "staffalteration-unfold statistics-label" : "statistics-label"}>
            {getFieldDecorator('time')(<RangePicker allowClear={false} style={{ width: 280 }} />)}
          </FormItem>
          <div className="staffalteration-filter statistics-filter">
            <div className="staffalteration-dashed"></div>
            <h4 className={alterationUnfoldPack ? "staffalteration-unfold" : ""}>已保存筛选方案：</h4>
            <Select
              placeholder="默认请选择"
              style={{ width: 160 }}
              value={statusVal}
              onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)}
              onSelect={onChecked}
              className={alterationUnfoldPack ? "staffalteration-unfold" : ""}

              dropdownMatchSelectWidth={false}
            >
              {
                filterContent.map((item, index) =>
                <Option value={index} key={index}>
                  <div className="closeStyBtn">
                    {item.name}
                    {flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                      e.stopPropagation()
                      onSearchDelete(item.id)
                    }} /></span> : null}
                  </div>
                </Option>
              )
              }
            </Select>
            <Button onClick={onSaveFilterTerm} className={alterationUnfoldPack ? "staffalteration-unfold" : ""}>保存筛选条件</Button>
            {/* <div className={alterationTextUnfoldPack ? "statistics-group" : ""}> */}
            <div>  
              <BtnGroup cancelName="重置" confirmName="筛选" onCancel={onReset} />
            </div>
            {/* <span className={alterationTextUnfoldPack ? "staffalteration-btn-a" : "staffalteration-btn-a statistics-btn-up"} style={{ marginTop: 6, fontSize: 12, color: '#1890ff', cursor: 'pointer' }} onClick={toggle}>
              {alterationTextUnfoldPack ? '展开' : '收起'}<Icon type={expand ? 'up' : 'down'} />
            </span> */}
            <Modal
              title="保存筛选条件"
              visible={visible}
              onOk={HandleOk}
              onCancel={HandleCancel}
            >
              <Input placeholder="请输入筛选名称" value={filterInfoValue} onChange={FilterInfoChange} />
            </Modal>
          </div>
        </Form>
      </div>
      <div className="line"></div>
      <div className="add-wrap">
        <Auth auths={authList} code="stat-current-attendance">
          <Button type="primary" onClick={attendanceMonth} loading={attendanceLoading}>汇总当月考勤</Button>
          <Modal
            title="汇总当月考勤"
            visible={visibleCollect}
            onOk={handleOkCollect}
            onCancel={() => {
              setValueCollect(undefined)
              setVisibleCollect(false)
            }}
            footer={[
              <Button key="back" onClick={() => {
                setValueCollect(undefined)
                setVisibleCollect(false)
              }}>取消</Button>,
              <Button key="submit" type="primary" loading={approveLoading} onClick={handleOkCollect}>确定</Button>
            ]}
          >
            <div>截止日期：
              <DatePicker 
                disabledDate={disabledDate} 
                value={valueCollect} 
                onChange={onChangeCollect}
                allowClear={false}
              />
            </div>
          </Modal>
        </Auth>
        <Auth auths={authList} code="export">
          <Tooltip title="导出" placement="bottom">
            <Button type="primary" icon="upload" onClick={onExportFile} style={{ marginLeft: 15 }}>导出</Button>
          </Tooltip>
        </Auth>
      </div>
      <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
      <Table
        size="middle"
        dataSource={list}
        rowKey="id"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 3500 }}
        onChange={onChangeTable}
      >
        <Column title="序号" dataIndex="number" fixed="left" width={80} />
        <Column title="月份" dataIndex="month" fixed="left" width={150} />
        <Column title="公司用名" dataIndex="userName" fixed="left" width={120} />
        <Column title="真实姓名" dataIndex="nickName" width={120} />
        <Column title="所属公司" dataIndex="cropName" width={150} />
        <Column title="事业群" dataIndex="crowd" width={150} />
        <Column title="部门" dataIndex="sectionName" width={200} />
        <Column title="职级" dataIndex='position' width={150} />
        <Column title="岗位级别" dataIndex="level" width={150} />
        <Column title="直接主管" dataIndex="lead" width={150} />
        <Column title="管理层" dataIndex="isLead" width={150} />
        <Column title="员工编号" dataIndex="no" width={150} />
        <Column title="入职日期" dataIndex="entry" width={150} />
        <Column title="离职日期" dataIndex="leaveOffice" width={150} />
        <Column title="工资结算日期" dataIndex="wage" width={150} />
        <Column title="工资结算时间" dataIndex="wageTimeCn" width={120} />
        <Column title="是否转正" dataIndex="formal" width={150} />
        <Column title="饭卡号" dataIndex="mealCard" width={150} />
        <Column title="应到天数" dataIndex="workDay" width={150} />
        <Column title="事假" dataIndex="things" width={150} />
        <Column title="非医疗期病假" dataIndex="nonMedicalSickLeave" width={150} />
        <Column title="医疗期病假(工作日)" dataIndex="workDayMedicalSickLeave" width={150} />
        <Column title="医疗期病假(节假日)" dataIndex="nonWorkDayMedicalSickLeave" width={150} />
        <Column title="病假" dataIndex="sick" width={150} />
        <Column title="公出" dataIndex="official" width={150} />
        <Column title="迟到" dataIndex="late" width={150} />
        <Column title="早退" dataIndex="leave" width={150} />
        <Column title="忘打卡" dataIndex="forget" width={150} />
        <Column title="旷工" dataIndex="absenteeism" width={150} />
        <Column title="年假" dataIndex="annualLeave" width={150} />
        <Column title="婚假" dataIndex="marriage" width={150} />
        <Column title="产检假" dataIndex="prenatal" width={150} />
        <Column title="产假" dataIndex="maternity" width={150} />
        <Column title="小产假" dataIndex="miscarriage" width={150} />
        <Column title="丧假" dataIndex="funeral" width={150} /> 
        <Column title="陪产假" dataIndex="paternity" width={150} />
        <Column title="育儿假" dataIndex="parental" width={150} />
        {/* <Column title="未出勤时长(小时)" dataIndex="noAttendanceLenth" width={150} /> */}
        <Column title="饭补" dataIndex="mealAllowance" width={150} />
      </Table>
      <Export
        show={showExport}
        onCancel={() => {
          setShowExport(false)
          productsRef.current.changeVal()
        }}
        tplUrl="getCheckInStatExportTpl"
        fieldsUrl="getCheckInStatTplItems"
        saveUrl="getCheckInStatSaveExportTpls"
        exportUrl="exportCheckInStat"
        method="getlist"
        parame={{ ExportType: 'checkinstat.getlist', ...exporfilter, id: selectedIds }}
        cRef={productsRef}
      />
    </div>
  )
}

export default Form.create()(Statistics)