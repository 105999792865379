/**
 * 模块名称: 资产采购管理列表页
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import moment from 'moment'
import { Link } from 'react-router-dom'
import {
  Table,
  Form,
  Input,
  Button,
  Select,
  message,
  DatePicker,
  Row,
  Col,
  Popconfirm,
  Switch
} from 'antd'
import CorpFilter from '@/components/CorpQuanQian'
import BtnGroup from '@/components/BtnGroup'
import { parseSearch } from '@/utils'
import Auth from '@/components/AuthMiddleware'
import CryptoJS from 'crypto-js'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
const { RangePicker } = DatePicker

let pageSize = 10
let currentPage = 1
let companyId = []
let searchParamsTmp = {
  limit: pageSize,
  page: currentPage,
  companyId: companyId,
  shouldMe: 0
}

const Purchase = (props) => {
  const { history, match, location } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
  const [authList, setAuthList] = useState([])
  const [count, setCount] = useState(0)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [assetType, setAssetType] = useState([])//获取费用类别
  const [assetPayType, setAssetPayType] = useState([])//获取付款方式
  const [busStatusList, setBusStatusList] = useState([])//获取业务状态
  const [isShow, setIsShow] = useState(false)//待审批
  const search = parseSearch(props.location.search)

  useEffect(() => {
    // console.log(history.location.state)
    if (!history.location.state) {
      currentPage = search.page ? +search.page : 1
      pageSize = search.limit ? +search.limit : 10
      searchParamsTmp = {
        limit: pageSize,
        page: currentPage,
        shouldMe: 0
      }
    } else {
      currentPage = search.page ? +search.page : currentPage
      pageSize = search.limit ? +search.limit : pageSize
      initGoback()
    }
    companyId = []
    common()
    getAssetList()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  //公共接口
  const common = () => {
    api.getVariousSelectionList().then(res => {
      setAssetType(res.types)//获取公司资产类别
      setAssetPayType(res.payTypes)//资产采购付款方式
      setBusStatusList(res.businessStatus)//获取业务状态
    }).catch(err => console.log(err))
  }

  const initGoback = () => {
    let timer = setTimeout(() => {
      initFieldsVal(searchParamsTmp)
      if (searchParamsTmp.shouldMe == 1) {
        setIsShow(true)
      }
      setFieldsValue({
        applyDate: searchParamsTmp.applyStartDate ? [moment(searchParamsTmp.applyStartDate), moment(searchParamsTmp.applyEndDate)] : undefined
      })
      clearTimeout(timer)
    }, 1000)
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  //点击选择所属公司
  const onChangeCorp = (data) => {
    currentPage = 1
    history.replace(match.path)
    if (data.length) {
      let arr = []
      data.forEach((item, index) => {
        arr.push(item.id)
      })
      companyId = [].concat(arr)
      searchParamsTmp = {
        limit: pageSize,
        page: currentPage,
        corpId: companyId,
        shouldMe: 0
      }
    } else {
      companyId = []
      searchParamsTmp = {
        limit: pageSize,
        page: currentPage,
        shouldMe: 0
      }
    }
    getAssetList()
  }

  //点击重置
  const onReset = () => {
    currentPage = 1
    history.replace(match.path)
    searchParamsTmp = {
      limit: pageSize,
      page: currentPage,
      corpId: companyId,
      shouldMe: 0
    }
    resetFields()
    setIsShow(false)
    getAssetList()
  }

  //分页点击
  const onChangeTable = (pagination, filters, sorter) => {
    searchParamsTmp.page = currentPage = pagination.current
    searchParamsTmp.limit = pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getAssetList()
  }

  //获取列表
  const getAssetList = () => {
    setLoading(true)
    if (companyId.length) searchParamsTmp.corpId = companyId
    api.getAssetList(searchParamsTmp).then(res => {
      setCount(res.count)
      setList(res.list)
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }

  //数据转换
  const transData = (vals) => {
    if (vals !== undefined) {
      for (let i in vals) {
        if (vals[i] !== undefined && i == 'applyDate') {
          searchParamsTmp.applyStartDate = moment(vals[i][0]).format('YYYY-MM-DD')
          searchParamsTmp.applyEndDate = moment(vals[i][1]).format('YYYY-MM-DD')
        } else {
          searchParamsTmp[i] = vals[i]
        }

      }
    }
    // console.log('searchParamsTmp=>', searchParamsTmp)
  }

  //点击筛选
  const searchSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      // console.log(vals)
      transData(vals)
      if (!err) {
        searchParamsTmp.page = currentPage = 1
        history.replace(match.path)
        getAssetList()
      }
    })

  }

  // 填充表单值
  const initFieldsVal = data => {
    setFieldsValue({
      id: data.id ? data.id : undefined,
      type: data.type ? data.type : undefined,
      payType: data.payType ? data.payType : undefined,
      creatorName: data.creatorName ? data.creatorName : undefined,
      businessStatus: data.businessStatus ? data.businessStatus : undefined
    })
  }

  //操作=> 删除
  const onDelete = (record) => {
    api.setAssetDel({ id: record.id }).then(res => {
      message.success('删除成功')
      getAssetList()
    })
  }

  //待审批
  const onSetShouldMe = (checked) => {
    currentPage = 1
    setIsShow(checked)
    if (checked) {
      searchParamsTmp.shouldMe = 1
    } else {
      searchParamsTmp.shouldMe = 0
    }
    setTimeout(() => {
      getAssetList()
    }, 600)
  }

  return (
    <>
      <CorpFilter onChange={onChangeCorp} part='feiyong' />
      <div className="comm-stay-lists">
        <Form onSubmit={searchSubmit} {...formItemLayout}>
          <Row gutter={24}>
            <Col span={6}>
              <FormItem label="业务编号">
                {getFieldDecorator('id')(<Input allowClear={true} />)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="资产类别">
                {getFieldDecorator('type')(<Select
                  placeholder="全部"
                  allowClear={true}
                  showSearch={true}
                  optionFilterProp="children"
                >
                  {
                    assetType.map(v => {
                      return <Option key={v.id}>{v.name}</Option>
                    })
                  }
                </Select>)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="付款方式">
                {getFieldDecorator('payType')(<Select
                  placeholder="全部"
                  allowClear={true}
                >
                  {
                    assetPayType.map((item, index) => {
                      return <Option key={item.id}>{item.name}</Option>
                    })
                  }
                </Select>)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="申请人">
                {getFieldDecorator('creatorName')(<Input allowClear={true} />)}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <FormItem label="申请时间">
                {getFieldDecorator('applyDate', {
                })(<RangePicker allowClear={false} />)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="状态">
                {getFieldDecorator('businessStatus')(<Select
                  placeholder="全部"
                  allowClear={true}
                >
                  {
                    busStatusList.map(item => {
                      return <Option key={item.id}>{item.name}</Option>
                    })
                  }
                </Select>)}
              </FormItem>
            </Col>
          </Row>
          <div className="comm-stay-btns" >
            <Switch 
              checkedChildren="待审批" 
              unCheckedChildren="待审批" 
              checked={isShow} 
              onClick={onSetShouldMe} 
              className="shouldme-switch-btn" 
            />
            <BtnGroup style={{marginLeft: '20px'}} cancelName="重置" confirmName="查询" onCancel={onReset} />
          </div>
        </Form>
      </div>
      <div className="line"></div>
      <div className="add-wrap">
        <Auth auths={authList} code="purchase-apply">
          <Link to={`${match.path}/apply`} style={{ cursor: 'pointer' }}>
            <Button type="primary">采购申请</Button>
          </Link>
        </Auth>
      </div>
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        loading={loading}
        className="askforleave-table"
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        onChange={onChangeTable}
        scroll={{ x: 1600 }}
      >
        <Column title="业务编号" dataIndex="id" fixed="left" />
        <Column title="资产类型" dataIndex="typeName" />
        <Column title="付款方式" dataIndex="payTypeName" />
        <Column title="付款金额" dataIndex="payMoney" />
        <Column title="申请人" dataIndex="creatorName" />
        <Column title="公司主体" dataIndex="corpName" />
        <Column title="申请时间" dataIndex="addTime" />
        <Column title="状态" dataIndex="businessStatusName" />
        <Column title="操作" key="oprate" fixed="right" width={100} render={(text, record) => {
          return <>
            <div>
              <Link to={`${match.path}/details?id=${record.id}&new=1&time=${+new Date()}&sign=${CryptoJS.MD5(record.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + (+new Date())).toString()}`}>详情</Link>
            </div>
            {record.editFlag && <div>
              <Link to={`${match.path}/apply?id=${record.id}`}>编辑</Link>
            </div>}
            {record.delFlag && <div>
              <Popconfirm title="请确认是否删除，删除后不可恢复。" cancelText="取消" okText="确认" onConfirm={() => onDelete(record)}>
                <a>删除</a>
              </Popconfirm>
            </div>}
          </>
        }} />
      </Table>
    </>
  )
}

export default Form.create()(Purchase)
