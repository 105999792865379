/**
 * 模块名称: 我的考勤
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import './assets/style.scss'
import BtnGroup from 'components/BtnGroup'
import api from 'api'
import { parseSearch } from 'utils'
import { upLoadModule } from 'utils/common'
import moment from 'moment'

import {
  DatePicker,
  Select,
  Table,
  Alert,
  Icon,
  Form,
  Drawer,
  Spin,
  Checkbox,
  TimePicker,
  Input,
  message,
  Upload,
  // Switch,
  Tooltip
} from 'antd'

const { Column } = Table
const { Dragger } = Upload;
const { RangePicker } = DatePicker
const Option = Select.Option
const FormItem = Form.Item
// const format = 'HH:mm'

// let editId = '' //编辑使用
let pageSize = 10
let currentPage = 1

let leaveData = {
  ask: '',
  sTime: '',
  eTime: ''
}

const Selfwork = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  const [timeValue, setTimeValue] = useState([])
  const [leaveSel, setleaveSel] = useState(undefined)
  const [leaveStat, setleaveStat] = useState({})
  const [leaveList, setleaveList] = useState([])
  const search = parseSearch(location.search)
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [visible, setVisible] = useState(false)  // 默认 改为false
  // const [switchChecked, setSwitchChecked] = useState(false)  // 开关
  const [formLoading, setformLoading] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [appealData, setAppealData] = useState({})
  const [timeDate, setTimeDate] = useState('')  // 列表传入时间
  const [checkInIdVal, setCheckInIdVal] = useState('')  // 列表传入checkInId
  const [twolist, setTwoList] = useState([])
  const [fileList, setFileList] = useState([])
  const draggerRef = useRef(null)
  const [signInDisable, setSignInDisable] = useState(true)
  const [signOutDisable, setSignOutDisable] = useState(true)
  const [signInStatus, setSignInStatus] = useState(false)
  const [signOutStatus, setSignOutStatus] = useState(false)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    leaveData = {
      ask: '',
      sTime: '',
      eTime: ''
    }
    getCheckInPersonList()
    onsetleaveList()
  }, [])

  const getCheckInPersonList = () => {
    setLoading(true)
    api.getCheckInPersonList({
      ask: leaveData.ask,
      sTime: leaveData.sTime,
      eTime: leaveData.eTime,
      limit: pageSize,
      page: currentPage
    }).then(data => {
      if (parseSearch(location.search).msgId) {
				api.setMessageReaded({ ids: [parseSearch(location.search).msgId] })
			}
      setList(data.list)
      setleaveStat(data.stat)
      setCount(data.count)
      // setSwitchChecked(data.notice == 1 ? true : false)//eslint-disable-line
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  // 筛选
  const onSearch = () => {
    leaveData.sTime = startTime
    leaveData.eTime = endTime
    history.replace(match.path)
    currentPage = 1
    getCheckInPersonList()
  }

  const onReset = () => {
    setleaveSel(undefined)
    leaveData.sTime = ''
    leaveData.eTime = ''
    leaveData.ask = ''
    setStartTime('')
    setEndTime('')
    setTimeValue([])
    currentPage = 1
    history.replace(match.path)
    getCheckInPersonList()
  }

  // 请假类型
  const onsetleaveList = () => {
    api.getLeaveOutAskType().then(data => {
      setleaveList(data.list)
    })
  }

  const onSetleaveSel = (e) => {
    setleaveSel(e)
    leaveData.ask = e
  }

  const onChangeTime = (e) => {
    setStartTime(e[0].format('YYYY-MM-DD'))
    setEndTime(e[1].format('YYYY-MM-DD'))
    setTimeValue(e)
  }

  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getCheckInPersonList()
  }

  // 文件上传
  const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.gif, .png, .jpg, .jepg, .PDF, .doc, .docx, .csv, .xlsx, .zip',
		allowSizeType: 2
	})

  // 申诉(编辑)
  const onEdit = (date) => {
    resetFields()
    setSignInDisable(true)
    setSignOutDisable(true)
    setFileList([])
    setVisible(true)
    setformLoading(true)
    setTimeDate(date.date)
    setCheckInIdVal(date.id)
    api.getAppealBaseData({ userId: date.userId, date: date.date })
      .then(date => {
        setformLoading(false)
        let infoData = date
        setFieldsValue({
          signIn: infoData.signIn ? moment(infoData.signIn, 'HH:mm') : '',
          signOut: infoData.signOut ? moment(infoData.signOut, 'HH:mm') : '',
        })
        setAppealData(infoData)
        setTwoList([infoData.checkInInfo])
      }).catch(() => setformLoading(false))
  }

  // const switchChange = (e) => {
  //   api.setCheckInHandleNotice({ status: e ? '0' : '1' }, 'post', true).then(data => {
  //     setSwitchChecked(e)
  //   })
  // }

  // 保存
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      let uploadList = []
      for (let i = 0, k = fileList.length; i < k; i++) {
        if (fileList[i].status !== 'removed') {
          uploadList.push({
            url: fileList[i].url,
            name: fileList[i].name
          })
        }
      }
      if (!err) {
        setSaveLoading(true)
        api.saveAppealHandle({
          checkInId: checkInIdVal,
          date: timeDate,
          signIn: vals.signIn == null || vals.signIn === "" ? '' : moment(vals.signIn).format('HH:mm'),
          signOut: vals.signOut == null || vals.signOut === "" ? '' : moment(vals.signOut).format('HH:mm'),
          remark: vals.remark,
          affix: uploadList
        })
          .then(() => {
            setSaveLoading(false)
            setVisible(false)
            message.success('申诉成功')
            getCheckInPersonList()
          })
          .catch(() => setSaveLoading(false))
      }
    })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    }
  }

  const onSignInChange = (e) => {
    setSignInDisable(!e.target.checked)
    setSignInStatus(e.target.checked)
  }

  const onSignOutChange = (e) => {
    setSignOutDisable(!e.target.checked)
    setSignOutStatus(e.target.checked)
  }

  return (
    <div className="self-con">
      <div className="self-wrap">
        <div className="filter-item">
          <label htmlFor="">请假类型：</label>
          <Select 
            placeholder="全部" 
            value={leaveSel} 
            style={{ width: 140 }} 
            onSelect={onSetleaveSel} 
            showSearch={true}
            optionFilterProp="children"
          >
            {
              leaveList.map((v, i) => {
                return <Option value={v.id} key={i}>{v.name}</Option>
              })
            }
          </Select>
        </div>
        <div className="filter-item">
          <label htmlFor="">创建时间：</label>
          <RangePicker allowClear={false} type="crateTime" onChange={onChangeTime} value={timeValue} />
        </div>
        <div className="self-wrap-btn">
          <BtnGroup cancelName="重置" confirmName="筛选" onConfirm={onSearch} onCancel={onReset} />
        </div>
      </div>
      {/* <div className="self-switch">
        <span className="self-switch-text">考勤异常邮件提醒</span><Switch onChange={switchChange} checked={switchChecked} />
      </div> */}
      <div className="line"></div>
      <div className="white-line"></div>
      <Alert
        className="corp-count"
        message={
          <>
            <span className="alert-title">迟到<span className="alert-num">{`${leaveStat && leaveStat.late ? leaveStat.late : 0}`}</span>次</span>
            <span className="alert-title">早退<span className="alert-num">{`${leaveStat && leaveStat.leave ? leaveStat.leave : 0}`}</span>次</span>
            <span className="alert-title">未打卡<span className="alert-num">{`${leaveStat && leaveStat.notCheck ? leaveStat.notCheck : 0}`}</span>次</span>
            {/* <span className="alert-title">打卡无效<span className="alert-num">{`${leaveStat && leaveStat.invalid ? leaveStat.invalid : 0}`}</span>次</span> */}
          </>}
        type="info"
        showIcon
      />
      <Table
        size="middle"
        dataSource={list}
        rowKey="id"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 1400 }}
        onChange={onChangeTable}
      >
        <Column title="序号" dataIndex="number" fixed="left" width={80} />
        <Column title="日期" dataIndex="date" fixed="left" width={120} />
        <Column title="姓名" dataIndex="userName" fixed="left" width={100} />
        <Column title="员工状态" dataIndex="statusName" fixed="left" width={100} />
        <Column title="签到" key="signIn" fixed="left" width={80} render={(text => (
          <span className={text.signInRed ? 'activeRed' : ''}>{text.signIn}</span>
        ))}
        />
        <Column title="签退" key="signOut" fixed="left" width={80} render={(text => (
          <span className={text.signOutRed ? 'activeRed' : ''}>{text.signOut}</span>
        ))}
        />
        <Column title="迟到" dataIndex="late" />
        <Column title="早退" dataIndex="leave" />
        <Column title="未打卡" dataIndex="notCheck" />
        {/* <Column title="打卡无效" dataIndex="invalid" /> */}
        <Column title="请假" dataIndex="ask_text" />
        <Column title="时长" dataIndex="long" />
        <Column title="直接上级" dataIndex="lead" />
        {/* <Column title="操作" key="set" fixed="right" width={100} render={(text, record) => (
          <>
            {
              record.appealFlag ?
                <Tooltip title="考勤申诉" placement="bottom">
                  <Icon
                    type="calendar"
                    style={{ fontSize: '18px', color: '#1890ff' }}
                    className="operate-icon" onClick={() => onEdit(record)}
                  />
                </Tooltip>
                :
                <Tooltip title="考勤不可申诉" placement="bottom">
                  <Icon type="calendar" style={{ fontSize: '18px' }} className="operate-icon" />
                </Tooltip>
            }
          </>
        )} /> */}
      </Table>
      <Drawer
        title="考勤申诉"
        width={600}
        onClose={() => setVisible(false)}
        visible={visible}
        destroyOnClose={true}
      >
        <Spin spinning={formLoading}>
          <Form onSubmit={formSubmit} {...formItemLayout}>
            <div className="self-wrap-appeal">
              <div className="appealtext appealtextOne">
                <span>姓名: {appealData.userName} </span>
                <span>员工编号: {appealData.no} </span>
                <span>员工ID: {appealData.userId} </span>
                <span>申诉日期: {timeDate}</span>
              </div>
              <div className="appealtext appealtextTwo">
                <span>部门: {appealData.sectionName}</span>
                <span>直属上级: {appealData.leader}</span>
                <span>类型: 考勤申诉</span>
              </div>
            </div>
            <Form.Item label="申诉内容">
              {getFieldDecorator('passtype', {
                // rules: [{ required: true, message: '请选择申诉内容' }]
                // , initialValue: ['0']
              })(
                <Checkbox.Group>
                  <Checkbox value="0" onChange={onSignInChange}>签到</Checkbox>
                  <Checkbox value="1" onChange={onSignOutChange}>签退</Checkbox>
                </Checkbox.Group>
              )}
            </Form.Item>
            <Form.Item label="签到">
              {getFieldDecorator('signIn', {
                rules: [{ required: signInStatus, message: '请选择签到时间' }]
              })(<TimePicker format="HH:mm" disabled={signInDisable} />)}
            </Form.Item>
            <Form.Item label="签退">
              {getFieldDecorator('signOut', {
                rules: [{ required: signOutStatus, message: '请选择签退时间' }]
              })(<TimePicker format="HH:mm" disabled={signOutDisable} />)}
            </Form.Item>
            <div className="self-wrap-intraday">当天考勤信息:</div>
            <Table
              dataSource={twolist}
              className="sort-table corp-table"
              pagination={false}
              rowKey="id"
            >
              <Column title="日期" dataIndex="date" />
              <Column title="姓名" dataIndex="userName" />
              <Column title="状态" dataIndex="statusName" />
              <Column title="签到" dataIndex="signIn" />
              <Column title="签退" dataIndex="signOut" />
              <Column title="迟到" dataIndex="late" />
            </Table>
            <Table
              dataSource={twolist}
              className="sort-table corp-table self-wrap-table"
              pagination={false}
              rowKey="id"
            >
              <Column title="早退" dataIndex="leave" />
              <Column title="未打卡" dataIndex="notCheck" />
              <Column title="打卡无效" dataIndex="invalid" />
              <Column title="请假外出" dataIndex="ask_text" />
              <Column title="时长" dataIndex="long" />
              <Column title="直属上级" dataIndex="leader" />
            </Table>
            <FormItem label="上传附件">
              <div className="dragger-box" ref={draggerRef}>
                <Dragger
                  {...uploadFiles}
                >
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                  <p className="ant-upload-hint">
                    支持扩展名：GIF、PNG、JPG、PDF、doc、docx、csv、.xlsx、zip、JEPG 单个文件大小不超过5M，不超过5个附件。
                  </p>
                </Dragger>
              </div>
            </FormItem>
            <FormItem label="备注">
              {getFieldDecorator('remark')(
                <Input.TextArea placeholder="请输入备注" />
              )}
            </FormItem>
            <BtnGroup onCancel={() => setVisible(false)} loading={saveLoading} />
          </Form>
        </Spin>
      </Drawer>
    </div>
  );
}

export default Form.create()(Selfwork)
