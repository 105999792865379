/**
 * 模块名称: 资产采购管理
 * @author liujingxue@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Purchase from './Purchase'
import Details from './Details'
import Apply from './Apply'
import '../assets/style.scss'

const PurchaseIndex = ({ match, history }) => {

	return (
		<Switch>
			<Route path={match.path + '/details'} component={Details} />
			<Route path={match.path + '/apply'} component={Apply} />
			<Route component={Purchase} />
		</Switch>
	)
}

export default PurchaseIndex