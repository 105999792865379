/**
 * 模块名称: 考勤申诉模块
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from 'api'
import {
  Table,
  Form,
  Button,
  Input,
  Icon,
  DatePicker,
  Tooltip,
  Select,
  Modal,
  message,
  Popconfirm,
  Drawer,
  Spin,
  Upload,
  Checkbox,
  TimePicker,
  Switch
} from 'antd'
import { parseSearch } from 'utils'
import { upLoadModule } from 'utils/common'
import { Link } from 'react-router-dom'
import CorpFilter from 'components/CorpFilter'
import BtnGroup from 'components/BtnGroup'
import Auth from 'components/AuthMiddleware'
import moment from 'moment'
import '../assets/style.scss'
import CryptoJS from 'crypto-js'

const { Column } = Table
const FormItem = Form.Item
const { Option } = Select
const { Dragger } = Upload
let pageSize = 10
let currentPage = 1
let apiOperateTimer = true
let shouldMe = null
const { RangePicker } = DatePicker

//筛选初始值
let exporfilter = {
  name: '',
  corpId: '',
  no: '',
  userId: '',
  checkNum: '',
  section: '',
  sTime: '',
  eTime: ''
}

let params = {}

const Appeal = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, resetFields, setFieldsValue, validateFields } = props.form

  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [department, setDepartment] = useState([])
  // const [personnelType, setPersonnelType] = useState([])
  const [visible, setVisible] = useState(false)
  const [visibleEdit, setVisibleEdit] = useState(false)
  const [filterContent, setFilterContent] = useState([])
  const [expand, setExpand] = useState(false)
  const [filterInfoValue, setFilterInfoValue] = useState('')
  const [alterationUnfoldPack, setAlterationUnfoldPack] = useState(true)
  const [alterationTextUnfoldPack, setAlterationTextUnfoldPack] = useState(true)
  const [statusVal, setStatusVal] = useState('请选择')
  const [authList, setAuthList] = useState([])
  const [formLoading, setformLoading] = useState(false)//eslint-disable-line
  const [appealData, setAppealData] = useState({})
  const [timeDate, setTimeDate] = useState('')  // 列表传入时间
  const [signInDisable, setSignInDisable] = useState(true)
  const [signOutDisable, setSignOutDisable] = useState(true)
  const [twolist, setTwoList] = useState([])
  const draggerRef = useRef(null)
  const [fileList, setFileList] = useState([])
  const [saveLoading, setSaveLoading] = useState(false)
  const [checkInIdVal, setCheckInIdVal] = useState('')  // 列表传入checkInId
  // const [checkInVal, setCheckInVal] = useState('')  // 列表传入checkInId
  const [attendanceId, setattendanceId] = useState()

  const [remark, setRemark] = useState('')
  const [signIn, setSignIn] = useState('')
  const [signOut, setSignOut] = useState('')
  const [signInStatus, setSignInStatus] = useState(false)
  const [signOutStatus, setSignOutStatus] = useState(false)
  const [statusValName, setStatusValName] = useState(undefined)
  const [flag, setFlag] = useState(false)
  const [shouldMeDis, setShouldMeDis] = useState(true)//风控，防止用户频繁点击switch

  const search = parseSearch(location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    exporfilter = {
      name: '',
      corpId: '',
      no: '',
      userId: '',
      checkNum: '',
      section: '',
      sTime: '',
      eTime: ''
    }
    shouldMe = null
    apiOperateTimer = true
    getAppealGetList()
    onCommonStaffAlteration()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  //获取考勤统计表
  const getAppealGetList = () => {
    if(apiOperateTimer){
      setLoading(true)
      let params = {
        limit: pageSize,
        page: currentPage,
        name: exporfilter.name,
        corpId: exporfilter.corpId,
        no: exporfilter.no,
        userId: exporfilter.userId,
        checkNum: exporfilter.checkNum,
        section: exporfilter.section,
        sTime: exporfilter.sTime,
        eTime: exporfilter.eTime
      }
      if(shouldMe){
        params.shouldMe = shouldMe
      }else{
        for(let i in params){
          if(i == 'shouldMe')delete params[i]
        }
      }
      api.getAppealGetList(params).then(data => {
        setList(data.list)
        setCount(data.count)
        setLoading(false)
      }).catch(() => setLoading(false))
      apiOperateTimer = false
      setShouldMeDis(false)
      let timer = setTimeout(() => {
        apiOperateTimer = true
        setShouldMeDis(true)
        clearTimeout(timer)
      }, 1000)
    }else{
      return message.info('请不要频繁操作')
    }
  }
  const onSetShouldMe = e => {
    if(e == true){
      shouldMe = 1
    }else{
      shouldMe = null
    }
    getAppealGetList()
  }

  //获取公共接口
  const onCommonStaffAlteration = () => {
    // api.getCommon({ type: 'staff_is_formal' }).then(data => setPersonnelType(data))
    api.getSelectDepartmentList({ limit: global.paramsLimit }).then(data => setDepartment(data.list)) //部门
  }

  const onChangeTable = (pagination) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getAppealGetList()
  }

  const HandleCancel = () => {
    setVisible(false) //点击保存筛选弹出框内取消
  }

  const onChangeGetFilter = (val) => {
    val ? setFlag(true) : setFlag(false)
    //展开保存筛选下拉框获取存储过的搜索内容
    val && api.getSearchStorage({ key: location.pathname }).then(data => {
      setFilterContent(data.list)
    })
  }

  const onChecked = (value, val2) => {
    setStatusVal(value)
    setStatusValName(val2.props.children.props.children[0])
    let filterCont = filterContent[value].content
    setFieldsValue({
      name: filterCont.name || filterCont.name == 0 ? filterCont.name : undefined,//eslint-disable-line
      no: filterCont.no || filterCont.no == 0 ? filterCont.no : undefined,//eslint-disable-line
      userId: filterCont.userId || filterCont.userId == 0 ? filterCont.userId : undefined,//eslint-disable-line
      checkNum: filterCont.checkNum || filterCont.checkNum == 0 ? filterCont.checkNum : undefined,//eslint-disable-line
      section: filterCont.section || filterCont.section == 0 ? filterCont.section : undefined,//eslint-disable-line
      time: filterCont.time || filterCont.time == 0 ? [moment(filterCont.time[0]), moment(filterCont.time[1])] : undefined //eslint-disable-line
    })//选择保存筛选下拉框中的内容

  }

  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: location.pathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }

  //点击筛选提交表单
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      // const values = Object.values(vals)
      // console.log(values)
      if (!err) {
        currentPage = 1
        exporfilter.name = vals.name || vals.name == 0 ? vals.name : ''//eslint-disable-line
        exporfilter.no = vals.no || vals.no == 0 ? vals.no : ''//eslint-disable-line
        exporfilter.userId = vals.userId || vals.userId == 0 ? vals.userId : ''//eslint-disable-line
        exporfilter.checkNum = vals.checkNum || vals.checkNum == 0 ? vals.checkNum : ''//eslint-disable-line
        exporfilter.section = vals.section || vals.section == 0 ? vals.section : ''//eslint-disable-line
        exporfilter.sTime = vals.time || vals.time == 0 ? vals.time[0].format('YYYY-MM-DD') : ''//eslint-disable-line
        exporfilter.eTime = vals.time || vals.time == 0 ? vals.time[1].format('YYYY-MM-DD') : ''//eslint-disable-line
        history.replace(match.path)
        getAppealGetList()
      }
    })
  }

  const onReset = (e) => {
    e.preventDefault()
    exporfilter = {
      name: '',
      corpId: '',
      no: '',
      userId: '',
      checkNum: '',
      section: '',
      sTime: '',
      eTime: ''
    }
    resetFields()
    shouldMe = null
    currentPage = 1
    history.replace(match.path)
    getAppealGetList()
    setStatusVal('请选择')
    setStatusValName(undefined)
  }

  const toggle = () => {
    setExpand(!expand)
    setAlterationUnfoldPack(!alterationUnfoldPack)
    setAlterationTextUnfoldPack(!alterationTextUnfoldPack)
  }

  const FilterInfoChange = (e) => {
    setFilterInfoValue(e.target.value) //获取保存筛选弹框中input值
  }

  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      if (!err) {
        const values = Object.values(vals)
        if (values.some(item => item !== undefined)) {
          params = {}
          for (let k in vals) {//eslint-disable-line
            if (vals[k] !== undefined) {
              if (k === 'addTime') {
                params[k] = [moment(vals[k][0]).format('YYYY-MM-DD'), moment(vals[k][1]).format('YYYY-MM-DD')]
              } else {
                params[k] = vals[k]
              }

            }
          }
          setVisible(true)
        } else {
          message.error('当前没有设置筛选条件')
        }
      }
    })
  }

  const HandleOk = () => {
    api.saveSearchStorage({
      name: filterInfoValue,
      key: location.pathname,
      content: params
    }).then(data => {
      setVisible(false)
      message.success('添加成功')
    }) //点击保存筛选弹出框内确定的回调函数
  }

  //筛选
  const onCorpFilter = (data) => {
    setDepartment([])
    setFieldsValue({'section': undefined})
    exporfilter.section = ''
    exporfilter.corpId = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getAppealGetList()
  }

  //选中所属公司后部门下拉框内容随之变化
  const onDepartmentId = () => {
    api.getSelectDepartmentList({ limit: global.paramsLimit, companyId: exporfilter.corpId }).then(data => setDepartment(data.list)) //部门
  }

  //删除
  const onDelete = (id) => {
    //console.log(id)
    api.saveAppealDelAppeal({ id }).then(() => {
      message.success('删除成功')
      getAppealGetList()
    })
  }

  //编辑
  const onEdit = (date) => {
    // console.log("odeit", date)
    resetFields()
    setTimeDate(date.date)
    setattendanceId(date.id)
    api.getAppealGetRow({ id: date.id, type: 0 }).then(data => {
      if (data.affix) {
        data.affix.forEach((item, index) => {
          fileList.push({
            status: 'done',
            uid: index,
            name: item.name,
            url: item.url
          })
        })
        setFileList(data.affix.map((item, i) => ({ status: 'done', uid: i, name: item.name, url: item.url })))
      } else {
        setFileList([])
      }
      setRemark(data.remark)
      setCheckInIdVal(data.checkInId)

      api.getAppealBaseData({ userId: date.userId, date: date.date }).then(date => {
        let infoData = date
        let signIn = infoData.signIn ? moment(infoData.signIn, 'HH:mm') : ''
        let signOut = infoData.signOut ? moment(infoData.signOut, 'HH:mm') : ''
        setSignIn(signIn)
        setSignOut(signOut)
        setAppealData(infoData)
        setTwoList([infoData.checkInInfo])
        setVisibleEdit(true)
      }).catch()
    })

  }

  // 保存
  const formSubmits = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      let uploadList = []
      for (let i = 0, k = fileList.length; i < k; i++) {
        if (fileList[i].status !== 'removed') {
          uploadList.push({
            url: fileList[i].url,
            name: fileList[i].name
          })
        }
      }
      if (!err) {
        setSaveLoading(true)
        api.saveAppealHandle({
          checkInId: checkInIdVal,
          date: timeDate,
          signIn: moment(vals.signIn).format('HH:mm'),
          signOut: moment(vals.signOut).format('HH:mm'),
          remark: vals.remark,
          affix: uploadList,
          id: attendanceId
        })
          .then(() => {
            setSaveLoading(false)
            setVisibleEdit(false)
            message.success('申诉成功')
            getAppealGetList()
          })
          .catch(() => setSaveLoading(false))
      }
    })
  }

  const onSignInChange = (e) => {
    setSignInDisable(!e.target.checked)
    setSignInStatus(e.target.checked)
  }

  const onSignOutChange = (e) => {
    setSignOutDisable(!e.target.checked)
    setSignOutStatus(e.target.checked)
  }

  // 文件上传 
  const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.gif, .png, .jpg, .jepg, .PDF, .doc, .docx, .csv, .xlsx, .zip, .rar',
		allowSizeType: 2
	})

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    }
  }

  return (
    <div className="">
      <CorpFilter onChange={onCorpFilter} />
      <div className="search-askforleave-wrap staffalteration-warp">
        <h4 className="title">其它选项：</h4>
        {
          alterationTextUnfoldPack ? <span className="askforleave-filter-control" onClick={toggle}>展开<Icon type="down" /></span>
          : <span className="askforleave-filter-control" onClick={toggle}>收起<Icon type="up" /></span>
        }
        <Form onSubmit={formSubmit} >
          <FormItem label="员工编号">
            {getFieldDecorator('no')(<Input placeholder="请输入员工编号" style={{ width: 160 }} />)}
          </FormItem>
          <FormItem label="公司用名">
            {getFieldDecorator('name')(<Input placeholder="请输入员工姓名" style={{ width: 160 }} />)}
          </FormItem>
          <FormItem label="部门">
            {getFieldDecorator('section')(
              <Select 
                placeholder="全部" 
                style={{ width: 200 }} 
                dropdownMatchSelectWidth={false} 
                onDropdownVisibleChange={bool => bool && onDepartmentId()}
                showSearch={true}
                optionFilterProp="children"
              >
                {
                  department.map((v, i) => {
                    return !exporfilter.corpId.length ? <Option title={v.name + '（' + v.companyName + '）'} key={v.id}>{v.name}（{v.companyName}）</Option> : <Option title={v.name} key={v.id}>{v.name}</Option>
                  })
                }
              </Select>)}
          </FormItem>
          <FormItem label="员工ID" className={alterationUnfoldPack ? "staffalteration-unfold" : ""}>
            {getFieldDecorator('userId')(<Input placeholder="请输入员工ID" style={{ width: 160 }} />)}
          </FormItem>
          <FormItem label="考勤号" className={alterationUnfoldPack ? "staffalteration-unfold" : ""}>
            {getFieldDecorator('checkNum')(<Input placeholder="请输入考勤号" style={{ width: 160 }} />)}
          </FormItem>
          <FormItem label="日期" style={{ marginLeft: '20px' }} className={alterationUnfoldPack ? "staffalteration-unfold" : ""}>
            {getFieldDecorator('time')(<RangePicker allowClear={false} style={{ width: 300 }} />)}
          </FormItem>
          <div className="staffalteration-filter">
            <div className="staffalteration-dashed"></div>
            <h4 className={alterationUnfoldPack ? "staffalteration-unfold" : ""}>已保存筛选方案：</h4>
            <Select
              placeholder="默认请选择"
              style={{ width: 160 }}
              value={statusVal}
              onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)}
              onSelect={onChecked}
              className={alterationUnfoldPack ? "staffalteration-unfold" : ""}

              dropdownMatchSelectWidth={false}
            >
              {
                filterContent.map((item, index) =>
                  <Option value={index} key={index}>
                    <div className="closeStyBtn">
                      {item.name}
                      {flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                        e.stopPropagation()
                        onSearchDelete(item.id)
                      }} /></span> : null}
                    </div>
                  </Option>
                )                    
              }
            </Select>
            <Button onClick={onSaveFilterTerm} className={alterationUnfoldPack ? "staffalteration-unfold" : ""}>保存筛选条件</Button>
            <Switch 
            checkedChildren="待审批" 
            unCheckedChildren="待审批" 
            disabled={!shouldMeDis} 
            onClick={onSetShouldMe} 
            className="shouldme-switch-btn" 
            checked={shouldMe ? true : false} 
          />
            <BtnGroup cancelName="重置" confirmName="筛选" onCancel={onReset} />
            <Modal
              title="保存筛选条件"
              visible={visible}
              onOk={HandleOk}
              onCancel={HandleCancel}
            >
              <Input placeholder="请输入筛选名称" value={filterInfoValue} onChange={FilterInfoChange} />
            </Modal>
          </div>
        </Form>
      </div>
      <div className="line"></div>
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        className="corp-table"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 1500 }}
        onChange={onChangeTable}
      >
        <Column title="员工编号" dataIndex="no" fixed="left" width={150} />
        <Column title="姓名" dataIndex="userName" width={200} render={(text, record) =>
          <>
            {
              <Link to={`${match.path}/details?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{text}</Link>
            }
          </>
        } />
        <Column title="部门" dataIndex="sectionName" width={260} />
        <Column title="职级" dataIndex='position' width={150} />
        <Column title="员工状态" dataIndex="statusName" width={150} />
        <Column title="申请时间" dataIndex="addTime" width={150} />
        <Column title="签到时间" dataIndex="signIn" width={150} />
        <Column title="签退时间" dataIndex="signOut" width={150} />
        <Column title="申请状态" dataIndex="auditName" width={150} />
        <Column title="操作" key="set" fixed="right" width={145} render={(text, record) => (
          <div>
            {
              record.editFlag ? <Tooltip title="编辑" placement="bottom">
                <Icon type="edit" style={{ color: '#1890FF' }} onClick={() => onEdit(record)} />
              </Tooltip> : <Icon type="edit" />
            }
            {''}
            <Auth auths={authList} code="delete">
              {
                record.delFlag ? <Tooltip title="删除">
                  <Popconfirm title="确认删除吗？" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                    <Icon type="delete" style={{ color: 'red' }} />
                  </Popconfirm>
                </Tooltip> : <Icon type="delete" />
              }
            </Auth>
          </div>
        )} />
      </Table>
      <Drawer
        title="考勤申诉"
        width={600}
        onClose={() => setVisibleEdit(false)}
        visible={visibleEdit}
        destroyOnClose={true}
      >
        <Spin spinning={formLoading}>
          {
            visibleEdit ? <Form onSubmit={formSubmits} {...formItemLayout}>
              <div className="self-wrap-appeal">
                <div className="appealtext appealtextOne">
                  <span>姓名: {appealData.userName} </span>
                  <span>员工编号: {appealData.no} </span>
                  <span>员工ID: {appealData.userId} </span>
                  <span>申诉日期: {timeDate}</span>
                </div>
                <div className="appealtext appealtextTwo">
                  <span>部门: {appealData.sectionName}</span>
                  <span>直属上级: {appealData.leader}</span>
                  <span>类型: 考勤申诉</span>
                </div>
              </div>
              <Form.Item label="申诉内容">
                {getFieldDecorator('passtype', {
                  // rules: [{ required: true, message: '请选择申诉内容' }]
                  // , initialValue: ['0']
                })(
                  <Checkbox.Group>
                    <Checkbox value="0" onChange={onSignInChange}>签到</Checkbox>
                    <Checkbox value="1" onChange={onSignOutChange}>签退</Checkbox>
                  </Checkbox.Group>
                )}
              </Form.Item>
              <Form.Item label="签到">
                {getFieldDecorator('signIn', {
                  initialValue: signIn, rules: [{ required: signInStatus, message: '请选择签到时间' }]
                })(<TimePicker format="HH:mm" disabled={signInDisable} />)}
              </Form.Item>
              <Form.Item label="签退">
                {getFieldDecorator('signOut', {
                  initialValue: signOut, rules: [{ required: signOutStatus, message: '请选择签退时间' }]
                })(<TimePicker format="HH:mm" disabled={signOutDisable} />)}
              </Form.Item>
              <div>当天考勤信息:</div>
              <Table
                dataSource={twolist}
                className="sort-table corp-table"
                pagination={false}
                rowKey="id"
              >
                <Column title="日期" dataIndex="date" />
                <Column title="姓名" dataIndex="userName" />
                <Column title="状态" dataIndex="statusName" />
                <Column title="签到" dataIndex="signIn" />
                <Column title="签退" dataIndex="signOut" />
                <Column title="迟到" dataIndex="late" />
              </Table>
              <Table
                dataSource={twolist}
                className="sort-table corp-table self-wrap-table"
                pagination={false}
                rowKey="id"
              >
                <Column title="早退" dataIndex="leave" />
                <Column title="未打卡" dataIndex="notCheck" />
                <Column title="打卡无效" dataIndex="invalid" />
                <Column title="请假外出" dataIndex="ask_text" />
                <Column title="时长" dataIndex="long" />
                <Column title="直属上级" dataIndex="leader" />
              </Table>
              <FormItem label="上传附件">
                <div className="dragger-box" ref={draggerRef}>
                  <Dragger
                    {...uploadFiles}
                  >
                    <p className="ant-upload-drag-icon">
                      <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                    <p className="ant-upload-hint">
                      支持扩展名：GIF、PNG、JPG、PDF、doc、docx、csv、.xlsx、zip、.rar、JEPG 单个文件大小不超过5M，不超过5个附件。
                  </p>
                  </Dragger>
                </div>
              </FormItem>
              <FormItem label="备注">
                {getFieldDecorator('remark', { initialValue: remark })(
                  <Input.TextArea placeholder="请输入备注" />
                )}
              </FormItem>
              <BtnGroup onCancel={() => setVisibleEdit(false)} loading={saveLoading} />
            </Form> : <div></div>
          }
        </Spin>
      </Drawer>
    </div>
  )
}

export default Form.create()(Appeal)