import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Appeal from './Appeals'
import Details from './AppealDetail'

const AppealIndex = ({ match, history }) => {

  return (
    <Switch>
      <Route path={match.path + '/details'} component={Details} />
      <Route component={Appeal} />
    </Switch>
  )
}

export default AppealIndex