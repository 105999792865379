/**
 * 模块名称: 资产采购管理 => 申请页 => 添加明细
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useImperativeHandle } from 'react'
import {
	Descriptions,
	Row,
	Col,
	Input,
	Form
} from 'antd'
import api from '@/api'
import BtnGroup from '@/components/BtnGroup'
import '../assets/style.scss'

const dItem = Descriptions.item

let arr = []

const AddDetail = (props) => {
	const { history, location, setVisible, setPaymentList, paymentList, setPayMoney, cRef } = props
	const { getFieldDecorator, validateFields, getFieldValue, setFieldsValue } = props.form
	const [loading, setLoading] = useState(false)
	const [data, setData] = useState({})
	const [num, setNum] = useState('')
	const [price, setPrice] = useState('')

	useEffect(() => {

	}, [])

	//再父组件中调用子组件的方法
	useImperativeHandle(cRef, () => ({
		// changeVal 就是暴露给父组件的方法
		changeVal: (data) => {
			setData(data)
			setNum(data.num)
			setPrice(data.price)
			setFieldsValue({
				// assetNo: data.assetNo,
				assetName: data.assetName,
				unit: data.unit,
				num: data.num,
				price: data.price,
				amount: data.amount,
				purpose: data.purpose,
				purchasePlatform: data.purchasePlatform,
				remark: data.remark
			})
		}
	}))

	//提交表单
	const onConfirm = (e) => {
		e.preventDefault()
		validateFields(async (err, vals) => {
			if (!err) {
				api.setAssetDetailHandle({
					// assetNo: vals.assetNo,
					assetName: vals.assetName,
					unit: vals.unit,
					num: vals.num,
					price: vals.price,
					amount: vals.amount,
					purpose: vals.purpose,
					purchasePlatform: vals.purchasePlatform,
					remark: vals.remark,
					id: data.id ? data.id : ''
				}).then(res => {
					vals.id = res
					if (JSON.stringify(data) !== "{}" && paymentList.some(v => v.ids == data.ids)) {
						paymentList.map(v => {
							if (v.ids == data.ids) {
								// v = vals
								// v.assetNo = vals.assetNo
								v.assetName = vals.assetName
								v.unit = vals.unit
								v.num = vals.num
								v.price = vals.price
								v.amount = vals.amount
								v.purpose = vals.purpose
								v.purchasePlatform = vals.purchasePlatform
								v.remark = vals.remark
								v.id = vals.id
							}
						})
					} else {
						paymentList.push(vals)
					}
					paymentList.map((v, i) => v.ids = i + 1)
					// paymentList.map((v, i) => v.id = res)
					let num = eval(paymentList.map(v => v.amount).join("+"))
					setPaymentList(paymentList)
					setPayMoney(num.toFixed(2))
					// console.log('paymentList=>', paymentList, num)
					setVisible(false)
				}).catch(err => console.log(err))
			}
		})
	}

	//数量
	const onChangeNum = (e) => {
		setNum(e.target.value)
	}

	const onBlurNum = () => {
		if (num && price) {
			setFieldsValue({amount: ((+num)*(+price)).toFixed(2)})
			// console.log((+num)*(+price))
		}
	}

	//单价
	const onChangePrice = (e) => {
		setPrice(e.target.value)
	}

	const onBlurPrice = () => {
		if (num && price) {
			setFieldsValue({amount: ((+num)*(+price)).toFixed(2)})
			// console.log((+num)*(+price))
		}
	}

	return (
		<>
			<Form onSubmit={onConfirm}>
				<Row gutter={16}>
					{/* <Col span={12}>
						<Form.Item label="资产编号">
							{getFieldDecorator('assetNo', {
								rules: [{ required: false, message: '请输入' },
								// { pattern: /^-?[0-9]*(\.[0-9]*)?$/, message: '请输入正确的格式' }
							]})(<Input placeholder="请输入" />)}
						</Form.Item>
					</Col> */}
					<Col span={12}>
						<Form.Item label="资产名称">
							{getFieldDecorator('assetName', {
								rules: [{ required: true, message: '请输入' }],
							})(<Input placeholder="请输入" />)}
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="单位">
							{getFieldDecorator('unit', {
								rules: [{ required: false, message: '请输入' }],
							})(<Input placeholder="请输入" />)}
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					
					<Col span={12}>
						<Form.Item label="数量">
							{getFieldDecorator('num', {
								rules: [{ required: true, message: '请输入' },
								{ pattern: /^\d+$/, message: '请输入正确的格式' }],
							})(<Input placeholder="请输入" onChange={onChangeNum} onBlur={onBlurNum} />)}
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="单价">
							{getFieldDecorator('price', {
								rules: [{ required: true, message: '请输入' },
								{ pattern: /^-?[0-9]*(\.[0-9]{0,2})?$/, message: '请输入正确的格式' }],
							})(<Input placeholder="请输入" onChange={onChangePrice} onBlur={onBlurPrice}/>)}
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					
					<Col span={12}>
						<Form.Item label="金额">
							{getFieldDecorator('amount', {
								rules: [{ required: true, message: '请输入' },
								{ pattern: /^-?[0-9]*(\.[0-9]{0,2})?$/, message: '请输入正确的格式' }]
							})(<Input placeholder="请输入" disabled={true}/>)}
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="用途">
							{getFieldDecorator('purpose', {
								rules: [{ required: false, message: '请输入' }],
							})(<Input placeholder="请输入" />)}
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					
					<Col span={12}>
						<Form.Item label="采购平台">
							{getFieldDecorator('purchasePlatform', {
								rules: [{ required: false, message: '请输入' }],
							})(<Input placeholder="请输入" />)}
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="备注">
							{getFieldDecorator('remark', {
								rules: [{ required: false, message: '请输入' }],
							})(<Input placeholder="请输入" />)}
						</Form.Item>
					</Col>
				</Row>
				{/* <Row gutter={16}>
					
				</Row> */}
				<BtnGroup cancelName="取消" confirmName="确认" onConfirm={onConfirm} onCancel={() => setVisible(false)} loading={loading} />
			</Form>
		</>
	)
}

export default Form.create()(AddDetail)