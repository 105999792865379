/**
 * 模块名称: 考勤申诉 => 考勤申诉详情
 * @author liujingxue@372163.com
 */
import React, { useState, useEffect } from 'react'
import api from 'api'
import {
	Steps,
	Descriptions,
	Button,
	Modal,
	Input,
	Icon,
	message,
	Spin,
	Table
} from 'antd'
import { parseSearch, debounce } from 'utils'
import CryptoJS from 'crypto-js'

const { Step } = Steps
const dItem = Descriptions.item
const { Column } = Table

let areaTxt = '', result = ''

const Details = (props) => {

	const { history, location } = props

	const [detail, setDetail] = useState({})
	const [flow, setFlow] = useState([])
	const [remark, setRemark] = useState([])
	const [affixList, setAffixList] = useState([])
	const [modalVisible, setModalVisible] = useState(false)
	const [pageId, setPageId] = useState('')
	const [auditRecord, setAuditRecord] = useState([])
	const [loading, setLoading] = useState(true)
	const [resPlaceholder, setResPlaceholder] = useState('请输入至少1个字符')
	const [attList, setAttList] = useState([])
	const [oldData, setOldData] = useState(null)
	// const [askType, setAskType] = useState('')

	//操作权限 
	const [auditAuth, setAuditAuth] = useState(false)//设置按钮组是否展示
	const [hasChargerPower, setHasChargerPower] = useState(false)//设置驳回和通过按钮是否展示
	const [replenish, setReplenish] = useState(false)//设置补充资料按钮是否展示
	const [returnUp, setReturnUp] = useState(false)//设置转上级按钮是否展示
	const [rejectMsgRequire, setRejectMsgRequire] = useState(false)//设置审批意见驳回必填
	const search = parseSearch(props.location.search)

	useEffect(() => {
		let plaintext = search.sign
		let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()

		if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
			areaTxt = ''
			result = ''
			// console.log(parseSearch(location.search).id)
			if (location.search) {
				setPageId(parseSearch(location.search).id)
				getLeaveOutRow(parseSearch(location.search).id)
			} else {
				history.push({
					pathname: '/attendance/appeal'
				})
			}
		} else {
			message.error('没有本页访问权限')
			setTimeout(() => {
				history.go(-2)
			}, 1000)
		}
	}, [])
	//获取日常办公审批单条的详情
	const getLeaveOutRow = (id) => {
		api.getAppealGetRow({ id: id, type: 0 }).then(res => {
			if (parseSearch(location.search).msgId) {
				api.setMessageReaded({ ids: [parseSearch(location.search).msgId] })
			}
			if (JSON.stringify(res) !== "[]") {
				let data = Object.assign({}, res)
				if (data.status === 0) data.current = 0
				if (data.status === 1) data.current = 1
				if (data.status === 2) data.current = 2
				if (data.status === 3) data.current = 3
				// console.log(data)
				setDetail(data)
				data.checkInData ? setAttList([data.checkInData]) : setAttList([])
				let auditFlow = data.audit_flow
				auditFlow.forEach((item, index, self) => {
					if (item.persons && item.persons.length) {
						self[index].persons = item.persons.join('，')
					}
				})
				setFlow(auditFlow)
				setAffixList(data.affix)
				setAuditRecord(data.audit_log)
				if (data.remark.length) {
					setRemark(data.remark)
				} else {
					setRemark('无')
				}
				if (data.auditAuth && JSON.stringify(data.auditAuth) !== '[]') {
					setAuditAuth(true)
					setHasChargerPower(data.auditAuth.hasChargerPower)
					setReplenish(data.auditAuth.replenish)
					setReturnUp(data.auditAuth.return_up)
					setRejectMsgRequire(data.auditAuth.reject_msg_require)
				} else {
					setAuditAuth(false)
					setHasChargerPower(false)
					setReplenish(false)
					setReturnUp(false)
					setRejectMsgRequire(false)
				}
				setOldData(data.oldData)
				if (data.oldData && data.oldData.ask) {
					//?
					api.getLeaveOutAskType().then(res => {
						res.list.forEach(item => {
							if (item.id == data.oldData.ask) {//eslint-disable-line
								data.oldData.askType = item.name
								//setAskType(item.name)
							}
						})
					})
				}
			} else {
				setDetail({})
				setFlow([])
				setAffixList([])
				setAuditRecord([])
				setOldData(null)
			}
			setLoading(false)
		})
	}
	//点击4个审批的按钮
	const leaveOutAudit = (no) => {
		areaTxt = ''
		result = no
		if (no == 0) {//eslint-disable-line
			setResPlaceholder('请输入审批意见')
			setModalVisible(true)
		} else if (no == 1) {//eslint-disable-line
			//审批意见是否为必填项改由审批流控制 2020年2月22日 17:19:14
			setResPlaceholder('请输入审批意见')
			setModalVisible(true)
		} else {
			api.saveAppealAudit({
				id: pageId,
				type: result,
				remark: areaTxt
			}).then(res => {
				//todo 转上级或者补充资料 返回列表页
				let timer = setTimeout(() => {
					history.push({
						pathname: '/attendance/appeal'
					})
					clearTimeout(timer)
				}, 1000)
			})
		}
	}
	const oprateHandleOk = () => {
		if (areaTxt.length < 1 && result == 1 && rejectMsgRequire) {//eslint-disable-line
			message.warning('请输入至少1个字符')
			return
		}
		let params = {
			id: pageId,
			type: result,
			remark: areaTxt
		}
		// saveAppealAudit
		api.saveAppealAudit(params).then(res => {
			message.success('审批成功')
			setModalVisible(false)
			getLeaveOutRow(pageId)
		})
	}
	//取消弹窗
	const oprateHandleCancel = () => {
		setModalVisible(false)
	}
	//同意的样式
	const agreeNode = () => {
		return result === 0 ? <>
			同意 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
		</> : <>
			不同意 <Icon type="close-circle" theme="twoTone" twoToneColor="#eb2f96" />
		</>
	}
	//审批意见
	const getAreaTxt = (e) => {
		areaTxt = e.target.value.trim()
	}
	return (
		<Spin spinning={loading}>
			<div className="add-newask-detail">
				<Descriptions title="考勤申诉申请单" layout="horizontal" className="pro-detail-title" style={{ borderBottom: 'none' }}>
					{detail.userName && <dItem label="申请人">{`${detail.userName}（ID：${detail.id}）`}</dItem>}
				</Descriptions>
				<div className="blank-line"></div>
				<div className="step-wrap">
					<div className="record-list-title">当前审批进度</div>
					<Steps current={detail.current}>
						{
							flow.map((item, index, self) => {
								let status = 'wait'
								if (item.time && item.time.length > 1) {
									status = 'process'
								}
								return <Step status={status} title={item.nodeName} key={index} description={
									<>
										{item.persons && <div title={item.persons}>{item.persons}</div>}
										<div title={item.statusName}>{item.statusName}</div>
										<div title={item.time}>{item.time}</div>
									</>
								} />
							})
						}
					</Steps>
				</div>
				<div className="blank-line"></div>
				<div className="ask-info">
					<div className="record-list-title">申请信息</div>
					<Descriptions layout="vertical" className="pro-detail-title" column={3} style={{ borderBottom: "none" }}>
						<dItem label="姓名">{detail.userName}</dItem>
						<dItem label="所属公司">{detail.corpName}</dItem>
						<dItem label="所属部门">{detail.sectionName}</dItem>
						<dItem label="职级">{detail.position}</dItem>
						<dItem label="直接主管">{detail.lead}</dItem>
						<dItem label="员工编号">{detail.no}</dItem>
						<dItem label="申诉日期">
							<>
								<div>{detail.addTime}</div>
								{oldData && <div>变更前：{oldData.addTime}</div>}
							</>
						</dItem>
						<dItem label="签到时间">
							<>
								<div>{detail.signIn}</div>
								{oldData && <div>变更前：{oldData.signIn}</div>}
							</>
						</dItem>
						<dItem label="签退时间">
							<>
								<div>{detail.signOut}</div>
								{oldData && <div>变更前：{oldData.signOut}</div>}
							</>
						</dItem>
					</Descriptions>
				</div>
				<div className="table-wrap">
					<div className="table-wrap-title">当天考勤信息</div>
					<Table
						dataSource={attList}
						rowKey="id"
						className="today-info"
						pagination={false}
					>
						<Column title="日期" dataIndex="date" />
						<Column title="姓名" dataIndex="userName" />
						<Column title="状态" dataIndex="statusName" />
						<Column title="签到" dataIndex="signIn" />
						<Column title="签退" dataIndex="signOut" />
						<Column title="迟到" dataIndex="late" />
						<Column title="早退" dataIndex="leave" />
						<Column title="未打卡" dataIndex="notCheck" />
						<Column title="打卡无效" dataIndex="invalid" />
						<Column title="请假外出" dataIndex="ask_text" />
						<Column title="时长" dataIndex="long" />
						<Column title="直属上级" dataIndex="lead" />
					</Table>
				</div>
				<Descriptions title="附件"
					layout="horizontal"
					className="pro-detail-smailtitle"
				>
					<div className="content">
						{
							affixList.length ? affixList.map((item, index) => {
								return <div key={index}><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></div>
							}) : '无'
						}
					</div>
				</Descriptions>
				<Descriptions title="备注"
					layout="horizontal"
					className="pro-detail-smailtitle"
				>
					<div className="content">
						{remark}
					</div>
				</Descriptions>
				<div className="blank-line"></div>
				<div className="record-list">
					<div className="record-list-title">审批记录</div>
					<Descriptions layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none", marginBottom: "-30px" }}>
						<dItem label="姓名"></dItem>
						<dItem label="时间"></dItem>
						<dItem label="状态"></dItem>
						<dItem label="审批意见"></dItem>
					</Descriptions>
					{
						auditRecord.map((item, index) => {
							return <Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index} style={{ borderBottom: "none", marginBottom: "-10px" }}>
								<dItem label="">{item.userInfo.staffName}</dItem>
								<dItem label="">{item.addTime}</dItem>
								<dItem label="">{item.status}</dItem>
								<dItem label="">{item.chargeMsg}</dItem>
							</Descriptions>
						})
					}
				</div>
				{
					auditAuth && <div className="btns">
						{replenish && <Button onClick={debounce(() => { leaveOutAudit(3) }, 3000)} className="orange-btn">补充资料</Button>}
						{returnUp && <Button onClick={debounce(() => { leaveOutAudit(2) }, 3000)} className="orange-btn">转上级</Button>}
						{hasChargerPower && <Button onClick={() => leaveOutAudit(1)}>驳回</Button>}
						{hasChargerPower && <Button onClick={() => leaveOutAudit(0)} type="primary">通过</Button>}
					</div>
				}
				<Modal
					title={agreeNode()}
					visible={modalVisible}
					onOk={debounce(() => { oprateHandleOk() }, 3000)}
					onCancel={oprateHandleCancel}
					destroyOnClose={true}
					footer={[
						<Button key="back" onClick={oprateHandleCancel}>取消</Button>,
						<Button key="submit" type="primary" onClick={debounce(() => {oprateHandleOk()}, 3000)}>确定</Button>
					]}
				>
					<div className="officework-modal-content">
						<span className="label">意见：</span><Input.TextArea className="content-area" placeholder={resPlaceholder} onChange={getAreaTxt} rows={4} style={{ width: "100%" }} maxLength={100} />
					</div>
				</Modal>
			</div>
		</Spin>
	)
}

export default Details